import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Welcome from '../pages/Welcome';
import { CONST_ROUTE_PATH } from '../routes/Routes';
import CourseDetail from '../pages/Courses/CourseDetail';
import ProtectedRoute from '../routes/ProtectedRoute';
import CourseOverview from '../components/common/content/tabsContent/CourseOverview';
import UserProfile from '../pages/UserProfile';
import CertificatePage from '../pages/CertificatePage';
import CourseVideos from '../pages/Courses/CourseVideos';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/Contact';
import PartnerSection from '../components/common/content/Partnersection';
import ResourcesPage from '../components/common/content/ResourcesPage';
import { FAQPage } from '../components/common/content/FAQPage';
import { MyCourses } from '../pages/Courses/MyCourses';

export const MainLayout = () => {
  return (
    <Routes>
      {/* Define the home route */}
      <Route path={CONST_ROUTE_PATH.HOME} element={<Welcome />} />

      {/* Protected routes */}
      <Route path={CONST_ROUTE_PATH.USER_DASHBOARD} element={<ProtectedRoute><CourseOverview /></ProtectedRoute>} />

      {/* The UserDashboard will handle the course detail rendering */}
      <Route path={`${CONST_ROUTE_PATH.USER_DETAILS}/:courseId`} element={<ProtectedRoute><CourseDetail /></ProtectedRoute>} />
      <Route path={CONST_ROUTE_PATH.USER_PROFILE} element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
      <Route path={CONST_ROUTE_PATH.CERTIFICATEPAGE} element={<ProtectedRoute><CertificatePage /></ProtectedRoute>} />
      <Route path="/course/:courseId/videos" element={<ProtectedRoute><CourseVideos /></ProtectedRoute>} />
      <Route path={CONST_ROUTE_PATH.MYCOURSES} element={<ProtectedRoute><MyCourses /></ProtectedRoute>} />
      <Route path={CONST_ROUTE_PATH.USER_PROFILE} element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
      <Route path={CONST_ROUTE_PATH.ABOUT} element={<AboutPage />} />
      <Route path={CONST_ROUTE_PATH.CONTACT} element={<ContactPage />} />
      <Route path={CONST_ROUTE_PATH.PARTNER} element={<PartnerSection />} />
      <Route path={CONST_ROUTE_PATH.RESOURCES} element={<ResourcesPage />} />
      <Route path={CONST_ROUTE_PATH.FAQ} element={<FAQPage />} />

    </Routes>
  );
};
