import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from '../components/common/Header';
import '../components/common/Style.css';
import CompanyValue from '../components/common/content/ComapnyValue';
import { Footer } from '../components/common/Footer';
import CoursesCard from '../components/common/content/CoursesCards';
import HomeVideo from '../assets/HomeVideo.mp4';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { CONST_ROUTE_PATH } from '../routes/Routes';
import CallToAction from '../components/common/content/CallToAction';
import { ContactBadge } from '../components/common/ContactBadge';
import ReviewsList from '../components/common/ReviewCard';
import FAQComponent from '../components/common/FAQComponent';
import CourseSlider from './Courses/CourseSlider';
import CustomBar from '../components/common/CustomBar';
import QuickLinks from '../components/common/content/QuickLinks';

const Welcome = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { apiToken } = useAuth();

  useEffect(() => {
    // Trigger visibility after the component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to ensure the animation plays

    return () => clearTimeout(timer);
  }, []);

  const handleclick = () => {
    if(apiToken){
      navigate(CONST_ROUTE_PATH.USER_DASHBOARD);
    }else{
      navigate(CONST_ROUTE_PATH.SIGN_IN)
    }
   
  };

  //http://88.222.214.173:8081/login/index.php
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Full-screen Video Background */}
      <Box
        sx={{
          position: 'relative',
          height: '75vh',
          overflow: 'hidden',
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            left: 0,
            width: '100vw',
            height: '75vh',
            objectFit: 'cover',
            zIndex: -1, // Ensure the video is behind the content
          }}
        >
          <source src={HomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Centered Content */}
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center', // Horizontally center
            alignItems: 'center', // Vertically center
            zIndex: 1, // Ensure the content is above the video
          }}
        >
          <Box
            className={isVisible ? 'welcome-slide-in' : ''} // Add the animation class
            maxWidth="md"
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.2)', // Changed opacity for better readability
              p: 5,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible ? 1 : 0,
              zIndex: 2, // Ensure this content is on top of the video
            }}
          >
            <Typography
              align="start"
              fontWeight={550}
              sx={{
                fontFamily:"sans-serif",
                fontStyle:"italic",
                color: '#FF6F2F',
                fontSize: { xs: '2rem', sm: '2rem', md: '3em', lg: '3rem' },
              }}
              mb={4}
              gutterBottom
            >
              Elevate Your Expertise with Courses Led by Industry Masters!
            </Typography>
            <Typography sx={{ color: "gray", fontWeight:550}} variant="h6" align="left">
              Master Cybersecurity, Web Development, and more with our industry-focused courses.
            </Typography>
            <Button
              onClick={handleclick}
              variant="contained"
              color="success"
              size="large"
              sx={{
                  marginTop: 3,
                  padding: '10px 30px',
                  borderRadius: 2,
                  fontStyle:"italic",
                  textTransform:"none",
                  fontWeight:600,
                  fontSize:"1rem",
                  backgroundColor: "#FF6F2F",
                  transition:"all 0.3s ease-in",
                  // transition: 'transform 0.3s ease',
                  '&:hover': {
                    backgroundColor: "#0F2A47",
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                },
              }}
          >
              Get Started for Free 
            </Button>
          </Box>
        </Box>
      </Box>
      <CustomBar/>

      {/* Additional content below the video */}
      <CompanyValue />
      <CoursesCard />
      {/* <CourseAdvisors /> */}
      <CourseSlider/>

      < CallToAction />
      <FAQComponent/>
      <QuickLinks/>
      <ReviewsList/>
      {/* <Testimonials /> */}
      <ContactBadge />
      <Footer />
    </>
  );
};

export default Welcome;


