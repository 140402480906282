import logo from './logo.svg';
import './App.css';
import { MainLayout } from './layouts/MainLayout';
import { UserContextProvider } from './context/UserContext';
import { AuthLayout } from './layouts/AuthLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Box } from '@mui/material';
import { CoursesContextProvider } from './context/CoursesContext';

function App() {
  const theme = createTheme()
  return (
    <>
      <UserContextProvider>
        <CoursesContextProvider>
        <ThemeProvider theme={theme}>
        <Router>
          <Box >
          <MainLayout />
          <AuthLayout />
          </Box>
          {/* Single Router to handle routes across the entire application */}
         
        </Router>
        </ThemeProvider>
        </CoursesContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
