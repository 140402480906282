import { REGISTRATION_TYPE } from "../components/common/Helper";
import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";

/**
 * @description Login Api Service
 */
const LoginApiService = {
  // Manual Login
  manual: async (data) => {
    try {
      if (Object.keys(data).length > 0) {
        data['loginType'] = REGISTRATION_TYPE.MANUAL
      }

      const response =  await APIService.post(APIConstants.ENDPOINT_SIGNIN, data)
       return response.data
    } catch (error) {
      console.error('Login Api Service: Caught error while login attempt', error, data)
      throw error; // Rethrow error to handle it in the UI

    }
  },

};

export default LoginApiService;
