import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext';

const CategoryCard = ({ course, onCourseSelect,onCourseLearn }) => {
  const { userdetails } = useAuth();
  // Check if the user is enrolled in the current course
  const isEnrolled = userdetails?.enrolledCourses.some(
    (enrolledCourse) => enrolledCourse.courseId === course._id
  );

  return (
    <>
      <Card
        sx={{
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '1vw',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        {/* Course Image */}
        <Box
          sx={{
            backgroundImage: `url(${course.thumbnailUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            borderRadius: '1vw',
            minHeight: '30vh',
          }}
        />

        {/* Hover Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            p:1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            opacity: 0,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Typography variant="body2" align="center" gutterBottom>
            {course.description}
          </Typography>

          {isEnrolled ? (
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F2F', color: '#000000', mt: 2 }}
              onClick={() => onCourseLearn(course)}
            >
              Learn
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F2F', color: '#000000', mt: 2 }}
              onClick={() => onCourseSelect(course)}
            >
              Enroll Now
            </Button>
          )}
        </Box>
      </Card>

      {/* Course Title and Price */}
      <CardContent sx={{ padding: 2, textAlign: 'center' }}>
        <Typography variant="h5" component="div" fontWeight="bold">
          {course.title}
        </Typography>
        {course.pricing && (
          <Typography variant="body2" color="text.secondary">
            Price: {course.pricing}
          </Typography>
        )}
      </CardContent>
    </>
  );
};

export default CategoryCard;
