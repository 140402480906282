import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";

/**
 * @description Contact Api Service
 */
const FreeEnrollApiService = {
  // Contact 
  FreeEnroll: async (data) => {
    try {
      const response =  await APIService.post(APIConstants.ENDPOINT_FREE_ENROLL, data)
       return response.data
    } catch (error) {
      console.error('Login Api Service: Caught error while login attempt', error, data)
      throw error; // Rethrow error to handle it in the UI

    }
  },

};

export default FreeEnrollApiService;
