import { createContext, useContext,  useEffect,  useState } from 'react';
import UserApiService from '../services/UserApiServices';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') || null);
  const [userdetails, setUserDetails]= useState(null);
  const [Tamptoken, setTamptoken]= useState(localStorage.getItem('tempToken') || null);
  const [userId , setUserId]=useState(localStorage.getItem('UserId') || null)
  // const [backendToken, setBackendToken] = useState(localStorage.getItem('backendToken') || null);

  const loginWithApi = (token,userId) => {
    setApiToken(token);
    setUserId(userId)
    localStorage.setItem('apiToken', token);
    localStorage.setItem('UserId', userId);
  };

  const logout = () => {
    setApiToken(null);
    localStorage.clear()
    sessionStorage.clear()
    // localStorage.removeItem('apiToken');
    // localStorage.removeItem('backendToken');
    // localStorage.removeItem('userDetails');
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDetails = await UserApiService.userDetails(userId);
      setUserDetails(userDetails?.data?.user);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (apiToken && userId) {
      fetchUserDetails(userId);
    }
  }, [apiToken, userId]);
  return (
    <AuthContext.Provider value={{ 
      apiToken, 
      userdetails,
      Tamptoken,
      fetchUserDetails,
      setUserDetails,
      loginWithApi, 
      logout,
      setTamptoken,
      userId      
      }}>
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => useContext(AuthContext);