import React from 'react';
import { Navigate } from 'react-router-dom'
import { CONST_ROUTE_PATH } from './Routes';
import { useAuth } from '../context/AuthContext';
export default function ProtectedRoute({ children }) {
    const { apiToken } = useAuth()
    if (!apiToken) {
        return <Navigate to={CONST_ROUTE_PATH.SIGN_IN} replace={true}></Navigate>
    }
    return children
}