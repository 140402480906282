// DirectoryCard.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, MenuItem, Slide,  IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CoursesApiService from '../../../services/CoursesApiServices';
import { useNavigate } from 'react-router-dom';
import { useCoursesContext } from '../../../context/CoursesContext';
import CategoryCard from './tabsContent/CategoryCard';



const DirectoryCard = () => {
  const navigate = useNavigate();
  const [categoryFilter, setCategoryFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [courses, setCourses] = useState([]);
  const { setSelectedCourse } = useCoursesContext();

  const handleFilterToggle = () => setFiltersVisible((prev) => !prev);

  const handleCategoryChange = (category) => setCategoryFilter(category);
  const handleTypeChange = (type) => setTypeFilter(type);

  const onCourseSelect = (course) => {
    setSelectedCourse([course]);
    navigate(`/courses-details/${course._id}`);
  };
  const onCourseLearn = (course)=>{
    setSelectedCourse([course]);
    navigate(`/course/${course._id}/videos`);

  }

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await CoursesApiService.getAllCourses();
        setCourses(res.courses);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourses();
  }, []);

  const filteredCourses = courses
    ?.filter((course) => (categoryFilter ? course.category === categoryFilter : true))
    ?.filter((course) => (typeFilter ? course.type === typeFilter : true))
    ?.sort((a, b) => {
      if (sortOrder === 'priceAsc') {
        return parseFloat(a.pricing?.replace('$', '')) - parseFloat(b.pricing?.replace('$', ''));
      }
      return 0;
    });

  const groupByCategory = (courses) => {
    return courses?.reduce((groups, course) => {
      if (!groups[course.category]) {
        groups[course.category] = [];
      }
      groups[course.category].push(course);
      return groups;
    }, {});
  };

  const groupedCourses = groupByCategory(filteredCourses);
  const uniqueCategories = [...new Set(courses?.map((course) => course?.category))];

  return (
    <Box sx={{ minHeight: '80vh', backgroundColor: '#FFF5EE', display: { md: 'flex' }, position: 'relative' }}>
      {/* Filter Section */}
      <Box>
        <IconButton onClick={handleFilterToggle} sx={{ zIndex: 2 }}>
          <FilterListIcon sx={{ color: '#FF6F2F' }} />
        </IconButton>

        <Box
          sx={{
            width: filtersVisible ? 300 : 0,
            padding: filtersVisible ? 2 : 0,
            backgroundColor: '#FFF5EE',
            position: { xs: 'absolute', md: 'relative' },
            zIndex: 2,
            transition: 'width 0.3s ease, padding 0.3s ease',
          }}
        >
          <Slide in={filtersVisible} timeout={300}>
            <Box>
           

              {/* Category Filter */}
              <Accordion defaultExpanded sx={{ boxShadow: 'none', border: 'none', bgcolor: '#FFF5EE' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Category</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {uniqueCategories.map((category) => (
                    <MenuItem key={category} onClick={() => handleCategoryChange(category)}>
                      {category}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => handleCategoryChange('')}>All Categories</MenuItem>
                </AccordionDetails>
              </Accordion>

              {/* Type Filter */}
              <Accordion defaultExpanded sx={{ boxShadow: 'none', border: 'none', bgcolor: '#FFF5EE' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Type</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MenuItem onClick={() => handleTypeChange('free')}>Free</MenuItem>
                  <MenuItem onClick={() => handleTypeChange('premium')}>Premium</MenuItem>
                  <MenuItem onClick={() => handleTypeChange('')}>All Types</MenuItem>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Slide>
        </Box>
      </Box>

      {/* Courses Display */}
      <Box sx={{ padding: 3, flexGrow: 1,  }}>
      {/* <Box
          sx={{
            backgroundImage: `url(${banner1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            height:"30vh",
            overflow: 'hidden',
            padding: '40px',
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            marginBottom: '50px',
          }}
        >
          <Typography variant="h4" align="center" fontStyle="italic" sx={{ fontWeight: 'bold', color: '#fff' }}>
          Free Courses for Cybersecurity 
          </Typography>
          <Typography variant="h6" align="center" sx={{ marginTop: '20px', color: '#fff' }}>
          Unlock the door to cybersecurity mastery with our free courses, offering expert insights and hands-on experience. Perfect for beginners or professionals, these courses help you protect yourself and your organization from the ever-evolving digital threats.


          </Typography>
        </Box> */}
        <Typography fontWeight={550} mb={5} textAlign="center" color="#FF6F2F" variant="h3" gutterBottom>
          Courses
        </Typography>

        {/* Iterate over categories in groupedCourses */}
        {Object.keys(groupedCourses).map((category) => (
          <Box key={category} sx={{ marginBottom: 4 }}>
            <Typography
              textAlign="start"
              variant="h5"
              fontWeight="bold"
              color="gray"
              sx={{ marginBottom: 2, ml: 2 }}
            >
              {category}
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {/* Iterate over courses in each category */}
              {groupedCourses[category].map((course) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={course.id}>
                  <CategoryCard course={course} onCourseSelect={onCourseSelect} onCourseLearn={onCourseLearn} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

      </Box>
    </Box>
  );
};

export default DirectoryCard;
