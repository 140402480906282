import React from 'react';
import { Box, Grid, Typography, Link, IconButton, Container } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon

export const Footer = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          color: 'white',
          padding: '3rem 0',
          borderTop: '1px solid #fff',
        }}
      >
        <Container>
          <Grid container spacing={5} justifyContent="space-between">
            {/* Company Info */}
            <Grid item xs={12} md={3}>
                    
                    <Box sx={{ textAlign: 'center', lineHeight: 1.2 }}>
                        <Typography variant='h6' color='white' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>INSTITUTE OF</Typography>
                        <hr />
                        <Typography variant='h6' color='white' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>CYBER SECURITY</Typography>
                    </Box>

              <Typography mt={1} variant="body2" sx={{ maxWidth: '250px' }}>
                ICS was formed with a mission to provide cutting-edge cybersecurity education and solutions. Our goal is to empower professionals and organizations to safeguard their digital assets and stay ahead of emerging cyber threats.
              </Typography>
              <Link href="/about" color="inherit" underline="none" sx={{ color: '#FF6F2F' }}>
                Read More...
              </Link>
              <Box mt={2}>
                <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <FacebookIcon />
                </IconButton>
                <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <TwitterIcon />
                </IconButton>
                <IconButton href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <LinkedInIcon />
                </IconButton>
                <IconButton href="https://www.youtube.com/results?search_query=infocus-it" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <YouTubeIcon />
                </IconButton>
                <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#fff' }}>
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Career Tracks */}
            <Grid item xs={12} md={2.8}>
              <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
                Free Resources
              </Typography>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Free Courses
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Blogs</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Security Awareness</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Documents</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Phishing</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Case Studies</Typography>
              </Link><Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Certifications</Typography>
              </Link>
            </Grid>

            {/* Trending Certifications */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
                Trending Courses

              </Typography>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Cybersecurity
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Web Development</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Mobile Development</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Data Science</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Cloud Computing</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Blockchain</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">DevOps</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">IT Compliance</Typography>
              </Link>
            </Grid>

            {/* Customer Service */}
            <Grid item xs={12} md={2.2}>
              <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
                Customer Service
              </Typography>
              <Link href="/our-partners" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Partner </Typography>
              </Link>
              <Link href="/about" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">About Us</Typography>
              </Link>
              <Link href="/contact-us" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Contact Us</Typography>
              </Link>
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Privacy Policy</Typography>
              </Link>
              
              <Link href="#" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">Terms of Service</Typography>
              </Link>
              <Link href="http://88.222.214.173:8081/login/index.php" color="inherit" underline="none" sx={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
                <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginRight: '0.5rem' }} />
                <Typography variant="body2">LMS</Typography>
              </Link>
            </Grid>
          </Grid>


        </Container>
        {/* Bottom Copyright */}

      </Box>
      <Box textAlign="center" sx={{ borderTop: '1px solid #444', padding: '1rem', backgroundColor: "red", display: "flex", alignItems: "center", justifyContent: "center" }}>

        <Typography sx={{ color: "white" }} variant="body2">
          Copyright 2024 © ICS All Rights Reserved. |
        </Typography>
        <Link href="#" sx={{ marginLeft: '1rem', color: '#fff' }} underline="none">Partner With Us |</Link>
        <Link href="#" sx={{ marginLeft: '1rem', color: '#fff' }} underline="none">Legal </Link>
      </Box></>
  );
};




