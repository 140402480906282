import { REGISTRATION_TYPE } from "../components/common/Helper";
import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";

/**
 * @description Registration API Service
 */
const RegistrationApiService = {
  /**
   * Manual Registration
   * @param {Object} data - Registration data (user details)
   * @returns {Promise} API response
   */
  manual: async (data) => {
    try {
      if (Object.keys(data).length > 0) {
        data['signUpType'] = REGISTRATION_TYPE.MANUAL;
      }
      const response = await APIService.post(APIConstants.ENDPOINT_REGISTRATION, data);
      return response.data;
    } catch (err) {
      console.error('Error during manual registration:', err);
      throw err; // Rethrow error to handle it in the UI
    }
  },

  /**
   * Send OTP for email and phone verification
   * @param {Object} data - Contains email and phone number
   * @returns {Promise} API response
   */
  sendOTPs: async (data) => {
    try {
      const response = await APIService.post(APIConstants.ENDPOINT_SEND_OTP, data, {
        withCredentials: true // This allows cookies to be sent and received
      });
      return response.data;
    } catch (err) {
      console.error('Error while sending OTPs:', err);
      throw err; // Rethrow error to handle it in the UI
    }
  },

  /**
   * Verify OTP for email and phone number
   * @param {Object} data - Contains emailOTP and whatsappOTP
   * @returns {Promise} API response
   */
  verifyOTPs: async (data) => {
    try {
      const response = await APIService.post(APIConstants.ENDPOINT_VERIFY_OTP, data);
      return response.data;
    } catch (err) {
      console.error('Error while verifying OTPs:', err);
      throw err; // Rethrow error to handle it in the UI
    }
  }

  
};

export default RegistrationApiService;
