import React from 'react';
import Header from '../Header';
import { Footer } from '../Footer';
import FAQComponent from '../FAQComponent';
import { Box, Typography } from '@mui/material';
import Faq from '../../../assets/Faq.png';

export const FAQPage = () => {
    return (
        <>
            <Header />
            <Box sx={{ height: "80vh", backgroundImage: `url(${Faq})`, backgroundSize: "cover", backgroundPosition: "center", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>
                {/* FAQ content over the image */}
                <Box sx={{ textAlign: "center", maxWidth: "80%", padding: "20px", borderRadius: "10px" }}>
                    <Typography fontStyle="italic" sx={{ fontWeight: 600, fontSize: {xs:"2.5rem", md:"3rem"}, marginBottom: "1rem", color:"#FF6F2F" }}>
                        Quick Solutions to Common Questions
                    </Typography>
                    <Typography sx={{ fontSize: "1.3rem", }}>
                        Here you will find answers to the most frequently asked questions about our courses, pricing, and services.
                        Whether you are unsure about the enrollment process or want to know more about our premium offerings, we've got you covered.
                    </Typography>
                </Box>
            </Box>
            <FAQComponent />
            <Footer />
        </>
    );
}
