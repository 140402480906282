import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Button, IconButton, CircularProgress, Alert, Snackbar } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from '../components/common/Header';
import { Footer } from '../components/common/Footer';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';
import FAQComponent from '../components/common/FAQComponent';
import image3 from '../assets/image3.png';
import ContactApiService from '../services/ContactApiService';

const ContactPage = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);  // Loading state
    const [message, setMessage] = useState('');     // Success or error message
    const [messageType, setMessageType] = useState(''); // Message type: 'success' or 'error'
    const [snackbarOpen, setSnackbarOpen] = useState(false);  // Snackbar visibility state


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string().required('Required'),
            subject: Yup.string().required('Required'),
            message: Yup.string().required('Required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true); // Start loading
            setMessage('');   // Reset message

            try {
                const response = await ContactApiService.Contact(values);

                if (response.message) {
                    setMessage('Your message has been sent successfully!'); // Success message
                    setMessageType('success');
                    resetForm();  // Clear form after success
                } else {
                    setMessage('Failed to send the message. Please try again.'); // Error message
                    setMessageType('error');
                }
            } catch (error) {
                setMessage('An error occurred while sending your message. Please try again.'); // Error message
                setMessageType('error');
            } finally {
                setLoading(false); // Stop loading
                setSnackbarOpen(true); // Show the Snackbar
            }
        },
    });

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Header />
            <Box
                sx={{
                    backgroundImage: `url(${image3})`,
                    backgroundSize: "cover",
                    backgroundPosition: 'center',
                    minHeight: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    position: 'relative',
                    padding: '2rem 0',
                    pb: 5
                }}
            >
                <Box
                    gap={10}
                    sx={{
                        padding: { xs: '1rem', md: '2rem' },
                        borderRadius: '10px',
                        zIndex: 1,
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        mt: { xs: "4rem" },
                        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
                        opacity: isVisible ? 1 : 0,
                        width: { xs: '90%', md: '80%' },
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Typography mt={0} fontStyle="italic" variant="h3" gutterBottom sx={{ color: 'white', fontWeight: 400 }}>
                            GET IN TOUCH
                        </Typography>
                        <Typography mt={0} fontStyle="italic" fontWeight={600} variant="h3" gutterBottom sx={{ color: '#FF6F2F' }}>
                            Let's connect and turn your learning aspirations into reality!
                        </Typography>
                        <Typography variant="h5" fontWeight={550} sx={{ color: 'gray', marginBottom: '1rem' }}>
                            For any inquiries, feel free to reach out to us using the contact form or the information provided below.
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray', marginBottom: '1rem' }}>
                            Our dedicated team is here to assist you with your educational needs and provide guidance on course selections, enrollment, and more!
                        </Typography>

                        <Grid container alignItems="center" sx={{ marginBottom: '1rem' }}>
                            <PhoneIcon sx={{ color: 'gray', marginRight: '8px' }} />
                            <Typography variant="h6" sx={{ color: 'gray', fontWeight: 600 }}>
                                Phone:
                            </Typography>
                            <Typography variant="body1" sx={{ marginLeft: '8px' }}>
                                +91-8178210903
                            </Typography>
                        </Grid>

                        <Grid container alignItems="center" sx={{ marginBottom: '1rem' }}>
                            <EmailIcon sx={{ color: 'gray', marginRight: '8px' }} />
                            <Typography variant="h6" sx={{ color: 'gray', fontWeight: 600 }}>
                                Email:
                            </Typography>
                            <Typography variant="body1" sx={{ marginLeft: '8px' }}>
                                ics.delhi41@gmail.com
                            </Typography>
                        </Grid>

                        <Typography variant="body1" sx={{ color: 'gray', marginBottom: '1rem' }}>
                            We appreciate your feedback and are eager to hear from you! Whether you're looking for information about our courses or need assistance, don’t hesitate to reach out.
                        </Typography>
                        <Typography variant="h5" fontWeight={600} sx={{ color: '#FF6F2F', marginBottom: '1rem' }}>
                            Connect with us on social media:
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item>
                                <IconButton href="https://facebook.com" target="_blank" aria-label="Facebook">
                                    <Facebook sx={{ color: '#FF6F2F' }} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton href="https://twitter.com" target="_blank" aria-label="Twitter">
                                    <Twitter sx={{ color: '#FF6F2F' }} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton href="https://linkedin.com" target="_blank" aria-label="LinkedIn">
                                    <LinkedIn sx={{ color: '#FF6F2F' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid mt={{ md: 7 }} pb={2} minHeight="60vh" container spacing={2}>
    {/* Display success/error message */}
    {message && (
        <Grid item xs={12}>
            <Alert severity={messageType} sx={{ mb: 2 }}>
                {message}
            </Alert>
        </Grid>
    )}
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
        <Grid item xs={12}>
            <Typography mb={1} variant='body1'>
                Your Name
            </Typography>
            <TextField
                label="Your Name"
                variant="outlined"
                fullWidth
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none', // No border
                        },
                        '&:hover fieldset': {
                            border: 'none', // No border on hover
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none', // No border when focused
                        },
                    },
                }}
            />
            {formik.touched.name && formik.errors.name && (
                <Typography color="error" variant="caption" sx={{ marginTop: 1 }}>
                    {formik.errors.name}
                </Typography>
            )}
        </Grid>

        {/* Wrap Phone and Email fields in a single container */}
        <Grid container spacing={2} item xs={12}>
            <Grid item xs={12} md={6}>
                <Typography mb={1} variant="body1">
                    Your Email
                </Typography>
                <TextField
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // No border
                            },
                            '&:hover fieldset': {
                                border: 'none', // No border on hover
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none', // No border when focused
                            },
                        },
                    }}
                />
                {formik.touched.email && formik.errors.email && (
                    <Typography color="error" variant="caption" sx={{ marginTop: 1 }}>
                        {formik.errors.email}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography mb={1} variant="body1">
                    Phone Number
                </Typography>
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // No border
                            },
                            '&:hover fieldset': {
                                border: 'none', // No border on hover
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none', // No border when focused
                            },
                        },
                    }}
                />
                {formik.touched.phone && formik.errors.phone && (
                    <Typography color="error" variant="caption" sx={{ marginTop: 1 }}>
                        {formik.errors.phone}
                    </Typography>
                )}
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Typography mb={1} variant='body1'>
                Subject
            </Typography>
            <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                name="subject"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none', // No border
                        },
                        '&:hover fieldset': {
                            border: 'none', // No border on hover
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none', // No border when focused
                        },
                    },
                }}
            />
            {formik.touched.subject && formik.errors.subject && (
                <Typography color="error" variant="caption" sx={{ marginTop: 1 }}>
                    {formik.errors.subject}
                </Typography>
            )}
        </Grid>

        <Grid item xs={12}>
            <Typography mb={1} variant='body1'>
                Message
            </Typography>
            <TextField
                label="Your Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                error={formik.touched.message && Boolean(formik.errors.message)}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none', // No border
                        },
                        '&:hover fieldset': {
                            border: 'none', // No border on hover
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none', // No border when focused
                        },
                    },
                }}
            />
            {formik.touched.message && formik.errors.message && (
                <Typography color="error" variant="caption" sx={{ marginTop: 1 }}>
                    {formik.errors.message}
                </Typography>
            )}
        </Grid>

        <Grid item xs={12}>
            <Button
                variant="contained"
                type="submit"
                sx={{ backgroundColor: '#FF6F2F', color: 'white', mt: 2 }}
                disabled={loading} // Disable button while loading
            >
                {loading ? <CircularProgress size={24} /> : 'Send Message'}
            </Button>
        </Grid>
    </form>
</Grid>

                </Box>
            </Box>
            <FAQComponent />
            <Footer />
            {/* Snackbar for success or error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={messageType} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ContactPage;
