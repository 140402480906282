export const CONST_ROUTE_PATH ={
      HOME:'/',
      ABOUT:'/about',
      CONTACT:"/contact-us",
      SIGN_IN: '/sign-in',
      USER_DASHBOARD:'/dashboard/courses',
      SIGN_UP: '/sign-up',
      FORGOT_PASSWORD: '/forgot-password',
      UPDATE_PROFILE:"/update-profile",
      USER_PROFILE:"/user-profile",
      CERTIFICATEPAGE:"/certificate",
      USER_LIST:"/user-list",
      USER_DETAILS: '/courses-details',
      COURSES_DETAILS:'/courses/:id',
      USER_VERIFICATION:'/user-verification',
      PARTNER:'/our-partners',
      RESOURCES:'/our-resources',
      FAQ:'/faq',
      MYCOURSES:'/my-courses',
      USER_PROFILE:'/user-profile'
      
}