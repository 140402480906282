import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";


const CoursesApiService = {
  // Fetch user details by email
  getAllCourses: async () => {
    try {
        const response =  await APIService.get(APIConstants.ENDPOINT_ALL_COURSES);
         return response.data
    } catch (err) {
      console.error('UserApiService: Caught error while fetching user details', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  getCoursesbyId: async (courseId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_COURSES_ID}?courseId=${courseId}`;
    const result = await APIService.get(endpoint);
     return result
    } catch (err) {
      console.error('UserApiService: Caught error while fetching user details', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  getCoursesbyuserId: async (userId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_USER_COURSES}?userId=${userId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Caught error while fetching user details', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  
  //SerachUser 
 
};

export default CoursesApiService;
