import React, { useEffect, useState } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, TextField, Button, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPlayer from './VideoPlayer'; // Updated VideoPlayer with quiz functionality
import Navbar from '../../components/common/Navbar';
import { CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { Footer } from '../../components/common/Footer';
import CoursesApiService from '../../services/CoursesApiServices';

const CourseVideos = () => {
  const { courseId } = useParams();

  // const {selectedcourse}=useCoursesContext()


  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedBreakpoints, setSelectedBreakpoints] = useState([]);
  const [videoDetails, setVideoDetails] = useState(null);
  const [comments, setComments] = useState([]); // State for comments
  const [newComment, setNewComment] = useState(''); // State for new comment
  const [rating, setRating] = useState(0); // State for rating
  const [activeVideoId, setActiveVideoId] = useState(null); // State to track active video
  const [selectedCourses, setSelectedCourses] = useState([]); // State for selected courses
  const [loading, setLoading] = useState(true); // Loading state for fetching courses
  const [error, setError] = useState(null); // Error state for fetching courses

   // Fetch course data based on courseId
   useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await CoursesApiService.getCoursesbyId(courseId); // Fetch course details using courseId
        setSelectedCourses(res?.data || []); // Assuming response structure has a 'course' field
        setLoading(false); // Set loading to false after fetching
      } catch (err) {
        setError('Error fetching course data'); // Set error if something goes wrong
        setLoading(false); // Set loading to false in case of error
        console.error('Error fetching course data:', err);
      }
    };

    if (courseId) {
      fetchCourses();
    }
  }, [courseId]);

  const course = selectedCourses?.find((course) => course._id === courseId);

  if (!course) {
    return <Typography variant="h6">Course not found</Typography>;
  }

  const handleVideoSelect = (video) => {
    setSelectedVideo(video.videoUrl); // Set the selected video URL
    setSelectedBreakpoints(video.breakpoints); // Set the quiz breakpoints for that video
    setVideoDetails(video);
    setActiveVideoId(video.id); // Set the active video id

  };

  const handleCommentSubmit = () => {
    if (newComment.trim()) {
      setComments([...comments, newComment]); // Add new comment to the list
      setNewComment(''); // Clear the input field
    }
  };

  const handleRating = (value) => {
    setRating(value); // Set the selected rating
  };

 

  return (
    <>
      <Navbar />
      {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', height:"60vh", alignItems:"center",backgroundColor: '#FFF5EE' }}>
                    <CircularProgress size={50} color="primary" />
                </Box>
            ) : (
      <Box sx={{ minHeight: '70vh', p: 0 }}>
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
          <Box sx={{ padding: 4, backgroundColor: 'white', minHeight: '90vh' }}>
            <Typography sx={{ color: '#FF6F2F', fontWeight: 600 }} variant="h4" gutterBottom>
              {course.title}
            </Typography>
            <Typography fontWeight={550} sx={{ color: "gray" }} variant="body1" gutterBottom>
              {course.description}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid mt={5} item xs={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: 'center',
                    minHeight: '60vh',
                    width: '100%',
                  }}
                >
                  <VideoPlayer videoUrl={selectedVideo} thumbnailUrl={course.thumbnailUrl} breakpoints={selectedBreakpoints} />
                  <Typography textAlign="start" sx={{ color: '#0F2A47' }} mt={2} variant='h5' fontWeight={550}>
                    {videoDetails?.title || 'N/A'}
                  </Typography>
                  <Typography textAlign="start" sx={{ color: 'gray' }} mt={2} variant='h6' fontWeight={550}>
                    {videoDetails?.objective || 'N/A'}
                  </Typography>


                </Box>
              </Grid>

              <Grid item mt={5} xs={12} md={4}>
                <Typography></Typography>
                {course.modules.map((module) => (
                  <Accordion  key={module.id} sx={{ boxShadow: 'none' }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={550} sx={{ color: "#0F2A47" }} variant="h6">
                        {module.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {module.videos.map((video) => (
                        <Typography
                          key={video.id}
                          
                          // fullWidth
                          sx={{
                            p: "1rem",
                            marginTop: '5px',
                            backgroundColor: activeVideoId === video.id ? '#F8F8FF' : 'white',
                            color: activeVideoId === video.id ? '#0F2A47' : '#FF6F2F',
                            '&:hover': { backgroundColor: '#F8F8FF', color: '#0F2A47' },
                            textTransform: 'none',
                            cursor:"pointer"
                          }}
                          
    
                          onClick={() => handleVideoSelect(video)} // Pass video object directly
                        >
                          {video.title}
                        </Typography>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              <Grid item xs={12} md={12}>
                {/* Rating Button */}
                <Box mt={3}>
                  <Typography variant="h6">Rate this course:</Typography>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Button

                      key={value}
                      onClick={() => handleRating(value)}
                      color="success"
                      variant={rating === value ? 'contained' : 'outlined'}
                      sx={{ margin: '5px' }}
                    >
                      {value}
                    </Button>
                  ))}
                  <Typography mt={1} sx={{ color: 'gray' }}>
                    Your rating: {rating ? rating : 'Not rated yet'}
                  </Typography>
                </Box>

                {/* Comments Section */}
                <Box mt={3}>
                  <Typography variant="h6">Comments:</Typography>
                  {comments.map((comment, index) => (
                    <Typography key={index} sx={{ marginTop: '5px', color: 'gray' }}>
                      {comment}
                    </Typography>
                  ))}
                  <Box sx={{ width: { md: "40vw" } }}>
                    <TextField
                      label="Add a comment"
                      variant="outlined"
                      fullWidth
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      sx={{ marginTop: '10px' }}
                    />
                  </Box>

                  <Button onClick={handleCommentSubmit} variant="contained" sx={{ marginTop: '10px', backgroundColor: "#0F2A47" }}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CSSTransition>
      </Box>)}
      <Footer />
    </>
  );
};

export default CourseVideos;


