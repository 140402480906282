export const courses = [
  {
    id: 1,
    title: 'Cybersecurity 101',
    description: 'An introduction to cybersecurity fundamentals and best practices to keep you safe online.',
    thumbnail: "https://static.vecteezy.com/system/resources/previews/001/410/879/large_2x/e-learning-online-education-futuristic-banner-vector.jpg",
    category: "Cybersecurity",
    type: 'free',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction',
        description: 'This module covers the basic concepts of cybersecurity, including its definition and importance in the digital world.',
        videos: [
          {
            id: 'v1',
            title: 'What is Cybersecurity?',
            url: 'https://www.youtube.com/watch?v=VEQd-jmVs44',
            objective: 'Define cybersecurity and its role in protecting digital information.',
            breakpoints: [
              { time: 5, quiz: { question: "What is cybersecurity?", options: ["Security", "Hacking"], correct: "Security" } }
            ]
          },
          {
            id: 'v2',
            title: 'Importance of Cybersecurity',
            url: 'https://www.youtube.com/watch?v=Z4F3AXvrLKo',
            objective: 'Discuss the significance of cybersecurity in safeguarding personal and organizational data.',
            breakpoints: [
              { time: 10, quiz: { question: "Why is cybersecurity important?", options: ["Safety", "Risk"], correct: "Safety" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Types of Cyber Attacks',
        description: 'Learn about different types of cyber attacks, their characteristics, and how they impact individuals and organizations.',
        videos: [
          {
            id: 'v3',
            title: 'Phishing Attacks',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Explain what phishing attacks are and how they trick users into revealing sensitive information.',
            breakpoints: [
              { time: 7, quiz: { question: "What is phishing?", options: ["Email scam", "Malware"], correct: "Email scam" } }
            ]
          },
          {
            id: 'v4',
            title: 'Malware Attacks',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Identify different types of malware and their potential impact on computer systems.',
            breakpoints: [
              { time: 8, quiz: { question: "What is malware?", options: ["Software", "Hardware"], correct: "Software" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 10,
    title: 'Network Security Basics',
    description: 'An introduction to cybersecurity fundamentals and best practices to keep you safe online.',
    thumbnail: "https://tse4.mm.bing.net/th?id=OIP.ZIGiQdbBLu5-QBWXKw89mgHaEK&pid=Api&P=0&h=180",
    category: "Cybersecurity",
    type: 'free',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction',
        description: 'This module covers the basic concepts of cybersecurity, including its definition and importance in the digital world.',
        videos: [
          {
            id: 'v1',
            title: 'What is Cybersecurity?',
            url: 'https://www.youtube.com/watch?v=VEQd-jmVs44',
            objective: 'Define cybersecurity and its role in protecting digital information.',
            breakpoints: [
              { time: 5, quiz: { question: "What is cybersecurity?", options: ["Security", "Hacking"], correct: "Security" } }
            ]
          },
          {
            id: 'v2',
            title: 'Importance of Cybersecurity',
            url: 'https://www.youtube.com/watch?v=Z4F3AXvrLKo',
            objective: 'Discuss the significance of cybersecurity in safeguarding personal and organizational data.',
            breakpoints: [
              { time: 10, quiz: { question: "Why is cybersecurity important?", options: ["Safety", "Risk"], correct: "Safety" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Types of Cyber Attacks',
        description: 'Learn about different types of cyber attacks, their characteristics, and how they impact individuals and organizations.',
        videos: [
          {
            id: 'v3',
            title: 'Phishing Attacks',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Explain what phishing attacks are and how they trick users into revealing sensitive information.',
            breakpoints: [
              { time: 7, quiz: { question: "What is phishing?", options: ["Email scam", "Malware"], correct: "Email scam" } }
            ]
          },
          {
            id: 'v4',
            title: 'Malware Attacks',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Identify different types of malware and their potential impact on computer systems.',
            breakpoints: [
              { time: 8, quiz: { question: "What is malware?", options: ["Software", "Hardware"], correct: "Software" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Web Development Bootcamp',
    description: 'Learn the basics of web development, including HTML, CSS, and JavaScript, and build your own website.',
    thumbnail: "https://tse3.mm.bing.net/th?id=OIP.vfnnsMcT0AKeWPrnAZ8kTQHaDy&pid=Api&P=0&h=180",
    category: "Web Development",
    type: 'premium',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: HTML Basics',
        description: 'Explore the foundational elements of HTML and learn how to structure your web pages effectively.',
        videos: [
          {
            id: 'v1',
            title: 'Introduction to HTML',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Introduce the basic concepts and syntax of HTML for building web pages.',
            breakpoints: [
              { time: 8, quiz: { question: "What does HTML stand for?", options: ["HyperText Markup Language", "HighText Markup Language"], correct: "HyperText Markup Language" } }
            ]
          },
          {
            id: 'v2',
            title: 'HTML Elements and Structure',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Demonstrate how to use HTML elements to create a well-structured web page.',
            breakpoints: [
              { time: 10, quiz: { question: "Which tag is used to create a paragraph?", options: ["<p>", "<h1>"], correct: "<p>" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: CSS Fundamentals',
        description: 'Understand the basics of CSS and learn how to style your web pages to enhance their visual appeal.',
        videos: [
          {
            id: 'v3',
            title: 'Introduction to CSS',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Explain the role of CSS in web development and its syntax for styling HTML elements.',
            breakpoints: [
              { time: 6, quiz: { question: "What does CSS stand for?", options: ["Cascading Style Sheets", "Computer Style Sheets"], correct: "Cascading Style Sheets" } }
            ]
          },
          {
            id: 'v4',
            title: 'CSS Selectors and Properties',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Teach how to use CSS selectors and properties to style HTML elements effectively.',
            breakpoints: [
              { time: 10, quiz: { question: "Which property is used to change the background color?", options: ["background-color", "color"], correct: "background-color" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Data Science for Beginners',
    description: 'Understand the basics of data science and how to work with data using Python and machine learning algorithms.',
    thumbnail: "https://tse3.mm.bing.net/th?id=OIP.UveR32_sfv_fOtPVI46GkQHaEK&pid=Api&P=0&h=180",
    category: "Data Science",
    type: 'premium',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction to Data Science',
        description: 'Learn what data science is and the key concepts that form the foundation of the field.',
        videos: [
          {
            id: 'v1',
            title: 'What is Data Science?',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Define data science and its importance in analyzing and interpreting data.',
            breakpoints: [
              { time: 5, quiz: { question: "What is the primary focus of data science?", options: ["Data Analysis", "Cybersecurity"], correct: "Data Analysis" } }
            ]
          },
          {
            id: 'v2',
            title: 'Tools for Data Science',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Introduce common tools and programming languages used in data science.',
            breakpoints: [
              { time: 10, quiz: { question: "Which programming language is widely used in data science?", options: ["Python", "JavaScript"], correct: "Python" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Machine Learning Basics',
        description: 'Dive into the fundamentals of machine learning and discover its significance in data science.',
        videos: [
          {
            id: 'v3',
            title: 'Introduction to Machine Learning',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Explain the concept of machine learning and its applications in various fields.',
            breakpoints: [
              { time: 8, quiz: { question: "What is machine learning?", options: ["Learning from data", "Learning from books"], correct: "Learning from data" } }
            ]
          },
          {
            id: 'v4',
            title: 'Supervised vs Unsupervised Learning',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Differentiate between supervised and unsupervised learning techniques in machine learning.',
            breakpoints: [
              { time: 12, quiz: { question: "Which learning type uses labeled data?", options: ["Supervised", "Unsupervised"], correct: "Supervised" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Cloud Computing Essentials',
    description: 'Learn the fundamentals of cloud computing, including its architecture, deployment models, and popular cloud platforms.',
    thumbnail: "https://www.oneeducation.org.uk/wp-content/uploads/2020/12/9.-Cloud-Computing-Essentials-1.jpg",
    category: "Cloud Computing",
    type: 'free',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction to Cloud Computing',
        description: 'Gain a foundational understanding of cloud computing and its significance in today’s digital landscape.',
        videos: [
          {
            id: 'v1',
            title: 'What is Cloud Computing?',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Define cloud computing and its key characteristics.',
            breakpoints: [
              { time: 6, quiz: { question: "What does cloud computing enable?", options: ["Remote access to data", "Local data storage"], correct: "Remote access to data" } }
            ]
          },
          {
            id: 'v2',
            title: 'Benefits of Cloud Computing',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Discuss the benefits of using cloud computing solutions.',
            breakpoints: [
              { time: 9, quiz: { question: "What is a major benefit of cloud computing?", options: ["Cost efficiency", "High maintenance"], correct: "Cost efficiency" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Cloud Service Models',
        description: 'Explore the different service models of cloud computing, including IaaS, PaaS, and SaaS.',
        videos: [
          {
            id: 'v3',
            title: 'IaaS, PaaS, and SaaS Explained',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Define and differentiate between Infrastructure as a Service, Platform as a Service, and Software as a Service.',
            breakpoints: [
              { time: 11, quiz: { question: "What does IaaS stand for?", options: ["Infrastructure as a Service", "Internet as a Service"], correct: "Infrastructure as a Service" } }
            ]
          },
          {
            id: 'v4',
            title: 'Popular Cloud Platforms',
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            objective: 'Introduce popular cloud platforms such as AWS, Azure, and Google Cloud.',
            breakpoints: [
              { time: 10, quiz: { question: "Which cloud platform is known for its vast range of services?", options: ["AWS", "Facebook"], correct: "AWS" } }
            ]
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'MERN Stack Development',
    description: 'Learn the fundamentals of the MERN stack (MongoDB, Express.js, React, and Node.js) to build full-stack web applications from scratch.',
    thumbnail: "https://thereadersea.com/wp-content/uploads/2023/05/mern-stack.png",
    category: "Web Development",
    type: 'free',
    modules: [
      {
        id: 'm1',
        title: 'Module 1: Introduction to the MERN Stack',
        description: 'Understand what the MERN stack is and why it’s popular for full-stack web development.',
        videos: [
          {
            id: 'v1',
            title: 'What is the MERN Stack?',
            url: 'https://www.example.com/videos/mern-introduction.mp4',
            objective: 'Define the MERN stack and its components.',
            breakpoints: [
              { time: 5, quiz: { question: "What does the 'R' in MERN stand for?", options: ["React", "Ruby"], correct: "React" } }
            ]
          },
          {
            id: 'v2',
            title: 'Overview of MongoDB',
            url: 'https://www.example.com/videos/mongodb-overview.mp4',
            objective: 'Explain the basics of MongoDB and its role in the MERN stack.',
            breakpoints: [
              { time: 8, quiz: { question: "What type of database is MongoDB?", options: ["SQL", "NoSQL"], correct: "NoSQL" } }
            ]
          },
        ],
      },
      {
        id: 'm2',
        title: 'Module 2: Setting Up the Backend with Node.js and Express.js',
        description: 'Learn how to set up the backend of a web application using Node.js and Express.js.',
        videos: [
          {
            id: 'v3',
            title: 'Introduction to Node.js',
            url: 'https://www.example.com/videos/nodejs-introduction.mp4',
            objective: 'Understand the basics of Node.js and its use in server-side programming.',
            breakpoints: [
              { time: 10, quiz: { question: "What is Node.js used for?", options: ["Front-end development", "Back-end development"], correct: "Back-end development" } }
            ]
          },
          {
            id: 'v4',
            title: 'Building an API with Express.js',
            url: 'https://www.example.com/videos/express-api.mp4',
            objective: 'Learn how to create a simple API using Express.js.',
            breakpoints: [
              { time: 12, quiz: { question: "What does Express.js simplify?", options: ["Database queries", "Routing and middleware"], correct: "Routing and middleware" } }
            ]
          },
        ],
      },
      {
        id: 'm3',
        title: 'Module 3: Building the Frontend with React',
        description: 'Understand how to use React to create interactive user interfaces for web applications.',
        videos: [
          {
            id: 'v5',
            title: 'Introduction to React Components',
            url: 'https://www.example.com/videos/react-components.mp4',
            objective: 'Learn the basics of React components and JSX.',
            breakpoints: [
              { time: 9, quiz: { question: "What is JSX?", options: ["A CSS framework", "JavaScript XML"], correct: "JavaScript XML" } }
            ]
          },
          {
            id: 'v6',
            title: 'State and Props in React',
            url: 'https://www.example.com/videos/react-state-props.mp4',
            objective: 'Understand how state and props work in React components.',
            breakpoints: [
              { time: 7, quiz: { question: "What is used to pass data between React components?", options: ["Props", "State"], correct: "Props" } }
            ]
          },
        ],
      },
      {
        id: 'm4',
        title: 'Module 4: Integrating Frontend and Backend',
        description: 'Learn how to connect the React frontend to the Node.js backend via API calls.',
        videos: [
          {
            id: 'v7',
            title: 'Connecting React to Express APIs',
            url: 'https://www.example.com/videos/react-express-integration.mp4',
            objective: 'Understand how to make API calls from a React frontend to an Express backend.',
            breakpoints: [
              { time: 10, quiz: { question: "Which method is commonly used to fetch data in React?", options: ["GET", "POST"], correct: "GET" } }
            ]
          },
          {
            id: 'v8',
            title: 'Fetching and Displaying Data in React',
            url: 'https://www.example.com/videos/fetch-data-react.mp4',
            objective: 'Learn how to fetch data from an API and display it in React components.',
            breakpoints: [
              { time: 8, quiz: { question: "Which React hook is used to fetch data?", options: ["useEffect", "useState"], correct: "useEffect" } }
            ]
          },
        ],
      },
    ],
  }

];
