import React, { useEffect, useState } from 'react';
import { Box, Typography,  Grid, Paper,  } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import Header from '../components/common/Header';
import { Footer } from '../components/common/Footer';
import EsteemedClientele from '../components/common/EsteemedClientele';
import { ContactBadge } from '../components/common/ContactBadge';


const AboutPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Trigger visibility after the component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to ensure the animation plays

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundImage: {xs: `url(${image3})` , md: `url(${image1})`  },
          backgroundSize: "100% 100%",
          height: "80vh",
          backgroundPosition: 'center',
          color: '#fff',

        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '70vh',
            display: 'flex',
            justifyContent: "left", // Horizontally center
            alignItems: 'center', // Vertically center
            zIndex: 1, // Ensure the content is above the video
          }}
        >
          <Box
            className={isVisible ? 'welcome-slide-in' : ''} // Add the animation class
            maxWidth="md"
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.2)', // Changed opacity for better readability
              p: 5,
              ml: { md: "4rem" },
              mt:"6rem",
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible ? 1 : 0,
              zIndex: 2, // Ensure this content is on top of the video
            }}
          >
            <Typography
              align="start"
              fontWeight={550}
              fontStyle="italic"
              sx={{
                color: '#FF6F2F',
                fontSize: { xs: '1.5rem', sm: '2rem', md: '3em', lg: '3rem' },
              }}
              mb={4}
              gutterBottom
            >
              About Our Company !

            </Typography>
            <Typography fontFamily="sans-serif" sx={{ color: "gray", fontSize: { xs: '1rem', sm: '1.5rem',  }, }} variant="h6" align="left">
              We provide a variety of high-quality courses, including Cybersecurity and Web Development. With a large number of enrolled students who have successfully completed our courses, we have established ourselves as a trusted learning platform. We are also proud to collaborate with leading training partners to ensure the best learning experience for all our students.


            </Typography>

          </Box>
        </Box>
      </Box>

      <Box sx={{
        backgroundColor: '#FFF5EE', p: { md: '3rem 9rem' }
      }}>
        {/* Company Overview Section */}
        <Box  >
          <Typography fontStyle="italic" variant="h4" sx={{ color: '#FF6F2F', fontWeight: 550, fontSize:"3rem" ,pt:{xs:"20px"}}} align="center" gutterBottom>
            Empowering Learning, Anytime, Anywhere          </Typography>
          <Typography variant="body1" sx={{ color: 'gray', mb: 6, fontWeight: 550 }} align="center">
            Our Learning Management System (LMS) is a robust platform designed to deliver high-quality education and training through online courses. Built with cutting-edge technology, the platform offers a seamless learning experience, whether you're looking to upskill in Web Development, Cybersecurity, or other IT domains.
          </Typography>

          {/* Achievements Section */}
          <Box
            sx={{
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: '#fff',
              py: 8,
              mb: 6,
            }}
          >
            <Box sx={{ p:'0rem 2rem' }}>
              <Typography fontStyle="italic" variant="h4" mb={5} align="center" gutterBottom>
                Our Achievements
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)',minHeight:"15vh" }}>
                    <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                    <Typography variant="h5" gutterBottom>
                      1,000+ Students
                    </Typography>
                    <Typography variant="body2">
                    Join over 1,000 students who have gained valuable skills and certifications through our comprehensive, expert-led courses.                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)' ,minHeight:"15vh" }}>
                    <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                    <Typography variant="h5" gutterBottom>
                      25+ Courses
                    </Typography>
                    <Typography variant="body2">
                    We provide over 50 courses across various fields, including Cybersecurity, Web Development, and other key specialties.                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)',minHeight:"15vh"  }}>
                    <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                    <Typography variant="h5" gutterBottom>
                      99% Satisfaction
                    </Typography>
                    <Typography variant="body2">
                    Our students and partners consistently praise the high-quality training, excellent support, and successful learning outcomes we provide.                   
                     </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <EsteemedClientele/>
        </Box>
      </Box>
      {/* <CourseAdvisors/> */}

      <ContactBadge/>

      <Footer />
    </>
  );
};

export default AboutPage;
