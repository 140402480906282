import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactPlayer from 'react-player';

const courses = [
  {
    title: "Certified Ethical Hacker",
    description: "Master the core domains of cybersecurity with practical learning from the world's No.1 ethical hacking certification.",
    videoUrl: "https://www.youtube.com/watch?v=y1dmSqtlou8",
    viewCourse: "#",
    brochure: "#",
  },
  {
    title: "Computer Hacking Forensic Investigator",
    description: "Gain the knowledge and skills to perform effective digital forensics investigations.",
    videoUrl: "https://www.youtube.com/watch?v=5oKMf9g-Bo4&t=35s",
    viewCourse: "#",
    brochure: "#",
  },
  {
    title: "Certified Chief Information Security Officer",
    description: "The training executives need to succeed at the highest levels of the security industry.",
    videoUrl: "https://www.youtube.com/watch?v=foVkmi9FvOM",
    viewCourse: "#",
    brochure: "#",
  },
  {
    title: "Another Course Title",
    description: "Gain the knowledge and skills to perform effective digital forensics investigations.",
    videoUrl: "https://www.youtube.com/watch?v=Imf7fCtQCbU",
    viewCourse: "#",
    brochure: "#",
  },
];

const CustomArrow = ({ direction, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      [direction]: '1rem',
      transform: 'translateY(-50%)',
      zIndex: 1,
      '&:hover': { backgroundColor: '#fff' },
    }}
  >
    {direction === 'left' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
  </Button>
);

const CourseSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [playingVideos, setPlayingVideos] = useState(Array(courses.length).fill(false));

  useEffect(() => {
    return () => {
      // Cleanup: Stop all videos when the component unmounts
      setPlayingVideos(Array(courses.length).fill(false));
    };
  }, []);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % courses.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? courses.length - 1 : prevSlide - 1));
  };

  useEffect(() => {
    const updatedPlayingVideos = Array(courses.length).fill(false);
    updatedPlayingVideos[currentSlide] = true;
    setPlayingVideos(updatedPlayingVideos);
  }, [currentSlide]);

//   const responsiveSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: { xs: 1, sm: 2, md: 3 }, // Adjust based on screen size
//     slidesToScroll: 1,
//     initialSlide: 0,
//     beforeChange: (current, next) => {
//       // Stop the currently playing video and play the next one
//       const updatedPlayingVideos = Array(courses.length).fill(false);
//       updatedPlayingVideos[next] = true;
//       setPlayingVideos(updatedPlayingVideos);
//       setCurrentSlide(next);
//     },
//   };

  return (
    <Box
      sx={{
        backgroundColor: '#FFF5EE',
        position: 'relative',
        minHeight: { xs: '50vh', md: '50vh' },
        p: { xs: '0.5rem', sm: '2rem', lg: '1rem 11rem' },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          mb: 5,
          fontSize: { xs: '1.5rem', lg: '1.7rem' },
          color: "gray",
          fontWeight: 550,
          fontStyle: "italic"
        }}
      >
        Experience a Free Course Demo - Unlock Your Learning Journey Today!
      </Typography>

      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
        <Box
       
          sx={{
            gap:{xs:0,md:5},
            display: 'flex',
          }}
        >
          {courses.map((course, index) => (
            <Card
              key={index}
              sx={{
                // minWidth: currentSlide === index ? '1 1 60%' : '1 1 40%', // Increase the width of the active card

                minWidth: { xs: '100%', md:"40%",lg:"30%" },
                borderRadius: '8px',
                height: { xs: '25vh', sm: '30vh', md: '35vh' },
                transition: 'transform 0.5s ease-in-out',
            transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              <CardContent sx={{ height: '100%', width: '100%', p: 0 }}>
                <ReactPlayer
                  url={course.videoUrl}
                  width="100%"
                  height="100%"
                  controls={true}
                  playing={ currentSlide === 0 ?false:playingVideos[index]}
                  light={currentSlide !== index}
                  muted={currentSlide !== index}
                />
              </CardContent>
            </Card>
          ))}
        </Box>

        <CustomArrow direction="left" onClick={handlePrev} />
        <CustomArrow direction="right" onClick={handleNext} />
      </Box>
    </Box>
  );
};

export default CourseSlider;


