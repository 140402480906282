import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Rating,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSSTransition } from 'react-transition-group';
import { useParams, useNavigate } from 'react-router-dom'; 
// import Navbar from '../../components/common/Navbar';
import FreeEnrollApiService from '../../services/FreeEnrollApiService';
import { useAuth } from '../../context/AuthContext';
import Navbar from '../../components/common/Navbar';
import { useCoursesContext } from '../../context/CoursesContext';

const CourseDetail = () => {
  const { courseId } = useParams();
  const {userId}=useAuth();
  const {selectedcourse}=useCoursesContext()
  const navigate = useNavigate(); 

  const course = selectedcourse.find(course => course._id == courseId);
  if (!course) {
    return <Typography variant="h6">Course not found</Typography>;
  }
  const handleEnrollClick = async () => {
    try {
      // Call the API to enroll the user in the course
      const response = await FreeEnrollApiService.FreeEnroll({ userId, courseId });
      console.log(response); // Handle the API response
      
      // Navigate to the video page after successful enrollment
      navigate(`/course/${courseId}/videos`);
    } catch (error) {
      console.error('Error enrolling in course:', error);
    }
  };

  return (
    <>
      <Navbar />
      <Box sx={{ minHeight: '70vh', p: 0 }}>
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
          <Box sx={{ padding: 4, backgroundColor: "#f0f8ff", minHeight: "90vh" }}>
            <Typography sx={{ color: "#FF6F2F", fontWeight: 600 }} variant="h4" gutterBottom>
              {course.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {course.description}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={9} lg={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 3,
                    backgroundImage: `url(${course.thumbnailUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat:"no-repeat",
                    height: { xs:"25vh",sm:"50vh"},
                    width: '100%',
                  }}
                >
                </Box>
              </Grid>

              <Grid item xs={12} md={9} lg={3}>
                <Card sx={{ backgroundColor: "#0F2A47", color: "white", mt: 3, minHeight: "50vh" }}>
                  <CardContent>
                    <Typography mt={2} fontWeight={600} sx={{ color: "#FF6F2F" }} variant="h5" component="div">
                      Course Features
                    </Typography>
                    <Typography mt={3} variant="h6">
                      <strong>Pricing:</strong> {course.type === 'free' ? 'free' : `${course.discountedPrice} (Discounted) / ${course.originalPrice}`}
                    </Typography>
                    <Typography mt={1} variant="h6"><strong>Certificate:</strong> Yes</Typography>
                    <Typography mt={1} variant="h6"><strong>Number of Videos:</strong> {course.modules.reduce((acc, module) => acc + module.videos.length, 0)}</Typography>
                    <Typography mt={1} variant="h6"><strong>Duration:</strong> 40hrs</Typography>
                    <Typography mt={1} variant="h6"><strong>Enrolled Students:</strong> 100+</Typography>
                    <Typography mt={1} variant="h6"><strong>Rating:</strong>
                      <Rating name="read-only" value={course.rating} precision={10} readOnly />
                    </Typography>
                    {course.type === 'premium' && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">
                          <strong>Mentor Details:</strong> John Doe - Cybersecurity Expert
                        </Typography>
                      </Box>
                    )}
                    <Typography mt={1} variant="h6">
                      <strong>Objective:</strong> {course.objective || "Understand the key concepts of the course."}
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{ mt: 3, backgroundColor: "#FF6F2F", color: "white" }}
                      onClick={handleEnrollClick} // Handle API call on click
                    >
                      {course.type === 'free' ? 'Enroll for Free' : 'Enroll Now'}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={9}>
                {course.modules.map(module => (
                  <Accordion key={module.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant='h6'>{module.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {module.videos.map(video => (
                        <Typography variant="body1" key={video.id} >
                          {video.title}
                        </Typography>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Box>
        </CSSTransition>
      </Box>
    </>
  );
};

export default CourseDetail;
