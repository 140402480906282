const APIConstants = {
    // User Endpoints
    ENDPOINT_REGISTRATION: 'api/auth/register/user',
    ENDPOINT_SIGNIN: 'api/auth/login/user',
    ENDPOINT_SEARCH_USER: 'api/user/search',
    ENDPOINT_SEND_OTP: 'api/auth/otp/send',
    ENDPOINT_VERIFY_OTP: 'api/auth/otp/verify',
    ENDPOINT_VERIFY_USER:"api/auth/verify/user",
    ENDPOINT_USER_DETAILS:"api/user/profile",
    ENDPOINT_CONTACT:"api/auth/contact",
    ENDPOINT_ALL_COURSES:"api/courses/get/courses",
    ENDPOINT_FREE_ENROLL:"api/enrollment/free/enroll",
    ENDPOINT_USER_COURSES:"api/courses/get/user/courses",
    ENDPOINT_COURSES_ID:"api/courses/get/courses/id"
    // ENDPOINT_UPDATE_USER: 'ecommerce/api/update',

    // // Address Endpoints
    // ENDPOINT_ADD_ADDRESS: 'ecommerce/api/add-address',
    // ENDPOINT_UPDATE_ADDRESS: 'ecommerce/api/update-address',
    // ENDPOINT_DELETE_ADDRESS: 'ecommerce/api/delete-address',

    // // Product Endpoints
    // ENDPOINT_CREATE_PRODUCT: 'ecommerce/api/addproducts',
    // ENDPOINT_UPDATE_PRODUCT: 'ecommerce/api/product/:productId',
    // ENDPOINT_DELETE_PRODUCT: 'ecommerce/api/product/:productId',
    // ENDPOINT_GET_ALL_PRODUCTS: 'ecommerce/api/products',
    // ENDPOINT_GET_PRODUCT_BY_ID: 'ecommerce/api/product/:productId',
    // ENDPOINT_GET_TOP_OFFERS: 'ecommerce/api/products/top-offers',
    // ENDPOINT_GET_TOP_TRENDING: 'ecommerce/api/products/top-trending',
    // ENDPOINT_GET_CATEGORIES: 'ecommerce/api/products/categories',
    // ENDPOINT_GET_BRANDS: 'ecommerce/api/products/brands',
    // ENDPOINT_SEARCH_PRODUCTS: 'ecommerce/api/products/search',
    // ENDPOINT_GET_SELLER_PRODUCTS: 'ecommerce/api/seller/:sellerId',
};

export default APIConstants;
