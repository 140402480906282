import React, { useState } from 'react';
import {
  AppBar, Toolbar, Button, Box, Menu,  IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery,
  MenuItem,
  Typography
} from '@mui/material';
import { AccountCircle,  Menu as MenuIcon } from '@mui/icons-material';
import {  useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import { CONST_ROUTE_PATH } from '../../routes/Routes';
import Logo2 from '../../assets/Logo.png'


const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Checks for mobile view
  const navigate = useNavigate();
  const { apiToken, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false); // Drawer for mobile

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Toggle Mobile Drawer
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  // Drawer content for mobile view
  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        <ListItem button onClick={() => navigate('/')}>
          <ListItemText primary="Home" />
        </ListItem>

        {apiToken && <Button onClick={logout} sx={{ color: 'black' }}>Log Out</Button>}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#FFF5EE", color: "black", width: "100%" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <img
            height="80px"
            src={Logo2}
            alt="logo"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
          <Box sx={{ ml: "-4rem", textAlign: 'center', lineHeight: 1.2 }}>
            <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>INSTITUTE OF</Typography>
            <hr />
            <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>CYBER SECURITY</Typography>
          </Box>
        </Box>
        {!isMobile && (<Box>

          {apiToken &&
            <Box >
              <Button sx={{ fontSize: "1rem", fontWeight: "550", mr: 2 }} color="inherit" onClick={() => navigate(CONST_ROUTE_PATH.USER_DASHBOARD)}>Home</Button>
              {/* <Button sx={{ fontSize: "1rem", fontWeight: "550", mr: 2 }} color="inherit" onClick={() => navigate(CONST_ROUTE_PATH.MYCOURSES)}>My learning</Button> */}
              <Button sx={{ fontSize: "1rem", fontWeight: "550", mr: 2 }} color="inherit" onClick={() => navigate(CONST_ROUTE_PATH.MYCOURSES)}>My Courses</Button>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                PaperProps={{
                  sx: {
                    bgcolor: "white",
                    backdropFilter: 'blur(20px)',
                    mt: "1rem",
                    width: "200px"
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={()=>navigate(CONST_ROUTE_PATH.USER_PROFILE)}>Profile</MenuItem>
                {apiToken && <MenuItem onClick={logout}>Logout</MenuItem>}
              </Menu>
            </Box>}
        </Box>)}
        {/* Mobile View Hamburger Icon */}
        {isMobile && (
          <IconButton edge="end" color="inherit" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        )}

        {/* Right-aligned Log Out button */}

      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileDrawerOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
