import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

const QuickLinks = () => {
  const links = [
    "Cybersecurity", "Web Development", "Mobile Development", "Data Science",
    "Cloud Computing", "Blockchain", "DevOps", "IT Compliance", "Free Courses", "Blogs",
    "Phishing", "Security Awareness", "Case Studies"
  ];

  return (
    <Box sx={{ textAlign: 'center', backgroundColor: '#FFF5EE', padding: 2 }}>
      <Typography
        variant="h4"
        fontFamily="sans-serif"
        textAlign="center"
        fontWeight={600}
        color="#FF6F2F"
        gutterBottom
      >
        Quick Links
      </Typography>
      <Container
        component="div"
        sx={{
          display: 'flex',
          mt: 3,
          flexWrap: 'wrap',
          gap: '0.6rem',
          justifyContent: 'center',
          padding: { xs: 1, sm: 2, md: 3 },
        }}
      >
        {links.map((link, index) => (
          <Button
            key={index}
            variant="contained"
            color="primary"
            sx={{
              color: 'white',
              fontStyle: "italic",
              fontWeight: 550,
              padding: { xs: '0.5rem 1rem', sm: '0.6rem 1.5rem', md: '0.7rem 2rem' },
              textTransform: 'none',
              fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
              minWidth: { xs: '8rem', sm: '10rem', md: '12rem' },
              transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.07)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                  },
            }}
          >
            {link}
          </Button>
        ))}
      </Container>
    </Box>
  );
};

export default QuickLinks;
