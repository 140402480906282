import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const clients = [
  {
    name: "Bank of America",
    imageUrl: "https://th.bing.com/th/id/OIP.rXvvbNFLY3Pk5AxQ4aQJywHaEA?w=319&h=180&c=7&r=0&o=5&pid=1.7"
  },
  {
    name: "Indian Oil",
    imageUrl: "https://th.bing.com/th/id/OIP.sLXXqWruAlaL1PkmHdY9yQHaEq?w=258&h=180&c=7&r=0&o=5&pid=1.7"
  },
  {
    name: "Dell",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/4/48/Dell_Logo.svg"
  },
  {
    name: "Fidelity",
    imageUrl: "https://logos-world.net/wp-content/uploads/2021/02/Fidelity-Logo.png"
  },
  {
    name: "HCL",
    imageUrl: "https://th.bing.com/th?id=OIP.X91J9zYYVC0XFOPGksn3uAHaEq&w=315&h=198&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2"
  },
  {
    name: "HDFC Bank",
    imageUrl: "https://th.bing.com/th?id=OIP.JpDAuqFwMnLpEsa5WC3oLwHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2"
  },
  
];

const EsteemedClientele = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '2rem' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '3rem', color: '#FF6F2F' }}>
        
      </Typography>

      <Grid container spacing={5} justifyContent="center" alignItems="center">
        {clients.map((client, index) => (
          <Grid item key={index} xs={6} sm={4} md={2}>
            <Box component="img"
              src={client.imageUrl}
              alt={client.name}
              sx={{ width: '100px', height: 'auto', objectFit: 'contain' }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EsteemedClientele;
