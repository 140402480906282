import React, { useState } from 'react';
import { Avatar, Box, Typography, Rating, IconButton, Card, CardContent, Grid } from '@mui/material';
import { ThumbUp, ThumbUpOutlined } from '@mui/icons-material';

// Sample reviews data
const reviews = [
    {
        userName: "UmaMaheswara",
        userAvatar: "https://yt3.googleusercontent.com/ytc/AIdro_msX_iEANDjcT-S89Qeg0zGsRoSHzE9aSQGqXhfPjwEmjwq=s160-c-k-c0x00ffffff-no-rj",
        rating: 4.5,
        date: "October 20, 2023",
        text: "Excellent  sessions  conducted  by an highly experienced  senior  consultant.👌. Amazingly   it's free of any cost",
        helpfulCount: 12,
    },
    {
        userName: "sachinchavan4994",
        userAvatar: "/path/to/avatar2.jpg",
        rating: 4.0,
        date: "October 21, 2024",
        text: "I really enjoyed your YouTube videos on ISO 27001-2022. I found information about your course on YouTube now, so I could not join this course. Could you please send me your videos and exercises of ISO 27001-2022, it will be very helpful for me.",
        helpfulCount: 8,
    },
    {
        userName: "salaheddine bel madani",
        userAvatar: "/path/to/avatar3.jpg",
        rating: 5.0,
        date: "October 22, 2023",
        text: "Thank you ,it 's well detailed course. Can share exemple of policies and procedures",
        helpfulCount: 20,
    },
    {
        userName: "Chaitnya Thoorpu",
        userAvatar: "https://yt3.ggpht.com/ytc/AIdro_kw0nRdlUbdcWauGpkpT4Kkv6IrdOzHyNxJBNvOhz0kPA=s88-c-k-c0x00ffffff-no-rj",
        rating: 3.5,
        date: "October 23, 2023",
        text: "Very good learning",
        helpfulCount: 7,
    },
    {
        userName: "Sai Srini",
        userAvatar: "https://yt3.googleusercontent.com/ytc/AIdro_lVkLmvFM7kBU5xFv5ZBPGGN4w1uVhg2qs6zmdxPtklSPk=s160-c-k-c0x00ffffff-no-rj",
        rating: 4.0,
        date: "October 27, 2023",
        text: "The session is very informative, thank you Mr Jagbir for all your efforts in making these sessions very informative with practical application.",
        helpfulCount: 8,
    },
    {
        userName: "trinity40",
        userAvatar: "https://yt3.ggpht.com/7jPbfLmFVsQxY9winuYIk3yBdqfbbCT_x-cgtRRN3LuOW5RxgVh-nSncZoSz1LL0vx6DX-DTWA=s88-c-k-c0x00ffffff-no-rj",
        rating: 5.0,
        date: "October 27, 2023",
        text: "Thank you for teaching sir",
        helpfulCount: 20,
    },
    {
        userName: "BaskarMurugan",
        userAvatar: "https://yt3.ggpht.com/ux4QIhrX-sHelPM_V-sSxlXALzXw5XU63envzmyKV_TFjoERHZ4FQfTqLgbon0IswfLyUnKraZo=s88-c-k-c0x00ffffff-no-rj",
        rating: 3.5,
        date: "October 28, 2023",
        text: "Great to have this videos and session. very much usefull.hand-pink-waving",
        helpfulCount: 7,
    },
    {
        userName: "Deepak Mishra",
        userAvatar: "https://yt3.googleusercontent.com/ytc/AIdro_mwLOGFMAnkjgFOzvJ7VyEb7h9I0kFkbR3zwOyByu8A22Nv=s160-c-k-c0x00ffffff-no-rj",
        rating: 5.0,
        date: "October 24, 2023",
        text: "very good for learning",
        helpfulCount: 20,
    },
    {
        userName: "aditya verma",
        userAvatar: "https://yt3.ggpht.com/ytc/AIdro_lovscm-VFC8NPnozF4k1tL8_0e9qYJOC-_pNj2xs4pdXw=s88-c-k-c0x00ffffff-no-rj",
        rating: 3.5,
        date: "October 28, 2023",
        text: "all these 4 videos will be sufficient to clear  iso 27001 Lead implementor exam ",
        helpfulCount: 7,
    }, {
        userName: "trinity40",
        userAvatar: "https://yt3.ggpht.com/7jPbfLmFVsQxY9winuYIk3yBdqfbbCT_x-cgtRRN3LuOW5RxgVh-nSncZoSz1LL0vx6DX-DTWA=s88-c-k-c0x00ffffff-no-rj",
        rating: 5.0,
        date: "October 27, 2023",
        text: "Thank you Jagbir sir. Very informative infos.",
        helpfulCount: 20,
    },
    {
        userName: "Mahfuz",
        userAvatar: "https://yt3.ggpht.com/pKXePo38WaMs6P54fRa67CZ6brTwt-JgX1tW-oVHPT2vfvmSw7aT9nOqVWS1UcdKGAMrosYWgmY=s88-c-k-c0x00ffffff-no-rj",
        rating: 3.5,
        date: "October 28, 2023",
        text: "Great to have this videos and session. very much usefull.hand-pink-waving",
        helpfulCount: 7,
    }, {
        userName: "Tejaswini Aradhya",
        userAvatar: "https://yt3.googleusercontent.com/ytc/AIdro_m79pQhwhyOXtRFqf_kawKqQ5i-y6rFuS3TYtaUNbwfgaFC=s160-c-k-c0x00ffffff-no-rj",
        rating: 5.0,
        date: "October 27, 2023",
        text: "Really very helpful sir",
        helpfulCount: 20,
    },
    {
        userName: "Archana R",
        userAvatar: "/",
        rating: 3.5,
        date: "October 28, 2023",
        text: "Thanks Sir, Very informative.",
        helpfulCount: 7,
    },
    {
        userName: "Jayanth",
        userAvatar: "https://yt3.googleusercontent.com/ytc/AIdro_kFoCne2y8MmKqvuqABM3cUSz8wDiu2ynD_ZnZUxxA=s160-c-k-c0x00ffffff-no-rj",
        rating: 5.0,
        date: "October 27, 2023",
        text: "A very information session on ISO 27001, well Navigated By Jagbir S, and this will be a good platform for beginners on ISO 27001... Thank you :)",
        helpfulCount: 20,
    },
    {
        userName: "Swetha B",
        userAvatar: "https://yt3.ggpht.com/ytc/AIdro_mrpuE3ywn8gywTX7OK89SNY3fViZqIM-oZQR1FxlZI20I=s88-c-k-c0x00ffffff-no-rj",
        rating: 3.5,
        date: "October 28, 2023",
        text: "Very good explanation of the risk assessment part. Thank you so much.",
        helpfulCount: 7,
    },
    // Add more reviews as needed
];

const ReviewCard = ({ review }) => {
    const [helpfulCount, setHelpfulCount] = useState(review.helpfulCount);
    const [liked, setLiked] = useState(false);

    const handleHelpfulClick = () => {
        setLiked(!liked);
        setHelpfulCount(liked ? helpfulCount - 1 : helpfulCount + 1);
    };

    return (
        <Card sx={{ padding: '0.5rem', boxShadow: 1, height: '100%' }}>
            <Box display="flex" alignItems="center">
                <Avatar src={review.userAvatar} alt={review.userName} sx={{ width: 70, height: 70, marginRight: 2, fontSize: "3rem" }} />
                <Box>
                    <Typography variant="subtitle2">{review.userName}</Typography>
                    <Typography variant="caption" color="textSecondary">{review.date}</Typography>
                </Box>
            </Box>
            <CardContent sx={{ padding: '0.5rem 0' }}>
                <Rating value={review.rating} readOnly precision={0.5} size="small" />
                <Typography variant="body2" sx={{ marginTop: 1, fontSize: '0.875rem' }}>{review.text}</Typography>
                <Box display="flex" alignItems="center" sx={{ marginTop: 1 }}>
                    <IconButton onClick={handleHelpfulClick} color={liked ? "primary" : "default"} size="small">
                        {liked ? <ThumbUp fontSize="small" /> : <ThumbUpOutlined fontSize="small" />}
                    </IconButton>
                    <Typography variant="caption" color="textSecondary">
                        {helpfulCount} found this helpful
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

const ReviewsList = () => (
    <Box sx={{ bgcolor: "#FFF5EE", height: "60vh",pb:7, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }} p={2}>
        <Typography variant='h4' fontFamily="sans-serif" fontStyle="italic" color="#FF6F2F" pb={4} fontWeight={550}>Reviews</Typography>
        <Box sx={{
            overflow: "auto", height: "50vh",
            '&::-webkit-scrollbar': { display: 'none' }, // Hides scrollbar in WebKit browsers (Chrome, Safari)
            '-ms-overflow-style': 'none', // Hides scrollbar in Internet Explorer and Edge
            'scrollbar-width': 'none' // Hides scrollbar in Firefox
        }}>
            <Grid container spacing={5} maxWidth={1200}>
                {reviews.map((review, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ReviewCard review={review} />
                    </Grid>
                ))}
            </Grid>
        </Box>

    </Box>
);

export default ReviewsList;
