import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';

const companyValues = [
  {
    icon: <SecurityIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Expert Instructors',
    description: 'Learn from highly experienced professionals who bring real-world expertise in fields like Cybersecurity, Web Development, and more. Our instructors are industry leaders, ensuring that you gain valuable knowledge directly from experts actively working in the field. Youll be prepared to face challenges with the right skills and guidance.',
  },
  {
    icon: <SupportAgentIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Flexible Learning',
    description: 'Study at your own pace, on your own time, and from any location. Our courses are designed to fit into your busy schedule, allowing you to access content and materials whenever you’re ready. Whether youre a working professional or a full-time student, you have the flexibility to learn on your terms.',
  },
  {
    icon: <SettingsIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Hands-on Projects',
    description: 'Engage in practical, real-life projects that give you the opportunity to apply the knowledge you’ve learned. Our courses emphasize hands-on experience, ensuring that you develop the technical skills required in the workplace. By the end, youll have a portfolio of completed projects to showcase to potential employers',
  },
  {
    icon: <GavelIcon fontSize="3rem" sx={{ color: '#FF6F2F' }} />,
    title: 'Certifications',
    description: 'Upon successful completion of the courses, earn industry-recognized certificates that can help accelerate your career. These certifications demonstrate your expertise and commitment to professional development, making you stand out to employers in a competitive job market.',
  },
  
];

const CompanyValue = () => {
  return (
    <Box sx={{ backgroundColor: '#FFF5EE', padding: '50px 20px', minHeight: '60vh' }}>
      <Box sx={{
        p: { xs: '0.5rem', lg: '2rem 9rem' }
      }}>

        <Box>
          <Typography textAlign="center" fontFamily="sans-serif" fontWeight={600} color="#FF6F2F" variant="h3" sx={{ fontSize: { xs: "2rem", md: "3rem" } }} gutterBottom>
            Why Learn with Us?
          </Typography>
          <Typography textAlign="center" fontWeight={600} color="gray" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }} variant="h6" gutterBottom>
            Unlock your potential with expert-led courses, flexible learning schedules, and hands-on projects designed to build real-world skills. Gain valuable certifications that set you apart and drive your career forward.
          </Typography>


        </Box>

        <Grid container spacing={6} mt={2} justifyContent="center" >
          {companyValues.map((value, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  textAlign: 'center',
                  padding: '0.5rem',
                  backgroundColor: '#0F2A47',
                  color: '#fff',
                  borderRadius: '12px',
                  boxShadow: 3,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ fontSize: "4rem" }}>{value.icon}</Box>
                  <Typography variant="h6" fontWeight={550} gutterBottom>
                    {value.title}
                  </Typography>
                  <Typography variant="body2">{value.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

    </Box>
  );
};

export default CompanyValue;
