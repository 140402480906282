import axios from 'axios';

// const API_BASE_URL = 'https://finops-aws-backend-service-cle5nunjtq-an.a.run.app';
// const API_BASE_URL = 'http://cloudsanalytics-backend-dev1.ap-south-1.elasticbeanstalk.com';
const API_BASE_URL =process.env.REACT_APP_BACKEND_URI;
const APIService = {
  // Generic function for making HTTP GET requests
  get: async (endpoint) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error making GET request to ${endpoint}:`, error);
      throw error;
    }
  },

  // Generic function for making HTTP POST requests
  post: async (endpoint, data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data);

      if (response.status !== 200) {
        throw new Error ('Caught error while saving details..', response)
      }

      return response.data;
    } catch (error) {
      console.error(`Error making POST request to ${endpoint}:`, error);
      throw error;
    }
  },

  // Generic function for making HTTP PUT requests
  put: async (endpoint, data) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error making PUT request to ${endpoint}:`, error);
      throw error;
    }
  },

  // Generic function for making HTTP DELETE requests
  delete: async (endpoint) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error making DELETE request to ${endpoint}:`, error);
      throw error;
    }
  },
};

export default APIService;
