import React from 'react';
import { Box,  Typography, Grid, Card, CardContent, Button } from '@mui/material';
import partnerBackground from '../../../assets/partnerBackground.png'; // Background image for the page
import { Footer } from '../Footer';
import Header from '../Header';

const ResourcesPage = () => {
  return (
    <>
    <Header/>
    <Box
      sx={{
        // backgroundImage: `url(${Plainbackgroud})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        backgroundColor:"#FFF5EE",
        p: { sm:'0.5rem', lg: '3rem 9rem' },
        color: '#fff',
      }}
    >
      <Box >
        {/* Banner Section */}
        <Box
          sx={{
            backgroundImage: `url(${partnerBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            height:"50vh",
            overflow: 'hidden',
            padding: '40px',
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            marginBottom: '50px',
          }}
        >
          <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', color: '#fff' }}>
            Free Resources for Cybersecurity Learning
          </Typography>
          <Typography variant="h6" align="center" sx={{ marginTop: '20px', color: '#fff' }}>
            Access a wide range of free documents, guides, and posters to help you enhance your knowledge in cybersecurity.
          </Typography>
        </Box>

        {/* Resources Section */}
        <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: "#FF6F2F" }}>
          Our Free Resources
        </Typography>
        <Typography variant="body1" align="center" sx={{ marginBottom: '40px', color: "gray" }}>
          Download documents, posters, and texts curated by industry experts. These resources are designed to provide
          comprehensive insights and practical knowledge on various cybersecurity topics.
        </Typography>

        {/* Mock Document Grid */}
        <Grid container spacing={4}>
          {resources.map((resource, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ backgroundColor: '#fff', color: '#333', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <img src='https://th.bing.com/th/id/OIP.cGYjJMTESCuR6llD2hAl5gHaHa?rs=1&pid=ImgDetMain' alt="Document Icon" style={{ width: '80px', marginBottom: '20px' }} />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {resource.title}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: '10px', marginBottom: '20px', color: '#555' }}>
                    {resource.description}
                  </Typography>
                  <Button variant="contained" href={resource.link} target="_blank" rel="noopener noreferrer" sx={{ backgroundColor: '#FF6F61', color: '#fff' }}>
                    Download
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Call to Action Section */}
        <Box textAlign="center" mt={6}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color:"#FF6F2F"}}>
            Start Your Learning Journey with ICS & INFOCUS-IT
          </Typography>
          <Typography variant="body1" sx={{ margin: '20px 0', color: 'gray' }}>
            Browse our free resources and access high-quality cybersecurity training with ICS and our partner INFOCUS-IT.
          </Typography>
          <Button variant="contained" size="large" href="/courses" sx={{ backgroundColor: '#ff6f61', color: '#fff' }}>
            Explore Our Courses
          </Button>
        </Box>
      </Box>
    </Box>
    <Footer/>
    </>
  );
};

// Mock data for resources
const resources = [
  {
    title: 'Cybersecurity Best Practices',
    description: 'A guide to essential cybersecurity practices for businesses and individuals.',
    link: '/assets/docs/cybersecurity-best-practices.pdf', // Mock download link
  },
  {
    title: 'Web Application Security',
    description: 'An in-depth document on securing web applications against modern threats.',
    link: '/assets/docs/web-application-security.pdf', // Mock download link
  },
  {
    title: 'Phishing Awareness Poster',
    description: 'A poster that educates employees about recognizing phishing attempts.',
    link: '/assets/posters/phishing-awareness-poster.pdf', // Mock download link
  },
  {
    title: 'Network Security Guide',
    description: 'Comprehensive steps to secure your network and prevent unauthorized access.',
    link: '/assets/docs/network-security-guide.pdf', // Mock download link
  },
  {
    title: 'ISO 27001 Compliance Checklist',
    description: 'A checklist to help you implement ISO 27001 for information security management.',
    link: '/assets/docs/iso-27001-checklist.pdf', // Mock download link
  },
  {
    title: 'Incident Response Poster',
    description: 'A quick reference poster for responding to cybersecurity incidents.',
    link: '/assets/posters/incident-response-poster.pdf', // Mock download link
  },
];

export default ResourcesPage;
