import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQComponent = () => {
  const faqs = [
    {
      question: 'What types of courses are available ?',
      answer: 'Our LMS offers a variety of IT courses, including Web Development, Cybersecurity, Network Security, Mobile Application Security, and Data Privacy. Each course is designed to provide practical, hands-on knowledge that can be applied immediately.',
    },
    {
      question: 'Are there any prerequisites for the courses?',
      answer: 'Most of our beginner courses do not have prerequisites, allowing anyone to start learning. Advanced courses may require basic knowledge in relevant fields, such as programming or network fundamentals. Prerequisites are listed on each course’s page.',
    },
    {
      question: 'How are the courses structured?',
      answer: 'Courses are divided into modules, each containing video lessons, quizzes, and hands-on projects. This structure helps reinforce learning and allows students to apply their knowledge in practical scenarios.',
    },
    {
      question: 'What is the course completion certificate?',
      answer: 'Upon successful completion of a course, learners receive a certificate that can be shared on LinkedIn, included in resumes, or used as a validation of skills. This certificate is issued after finishing all modules and assessments in the course.',
    },
    {
      question: 'Are the courses self-paced?',
      answer: 'Yes, most of our courses are self-paced, allowing you to learn at your own speed. However, certain live courses with mentorship are also available, providing real-time interaction with instructors.',
    },
    {
      question: 'Do you offer support or mentorship?',
      answer: 'Yes, we offer mentorship in selected courses. Additionally, our support team and community forums are available to help answer questions and assist you throughout your learning journey.',
    },
  ];

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto',bgcolor:"#FFF5EE",  p: { xs:'0.5rem', lg: '3rem 9rem' }
}}>
      <Typography variant="h4"  sx={{fontSize:{xs:"2rem" , md:"2.5rem"}}} fontStyle="italic" align="left" mb={{md:5}} ml={3} color="#FF6F2F" fontWeight={600} gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: 1, borderRadius: 2, margin: '0.5rem 0', }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#FF6F2F' }} />}>
            <Typography fontFamily="sans-serif" variant="h6" fontWeight="500" color="#333">
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" color="textSecondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQComponent;
