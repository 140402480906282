import React, { Fragment, useEffect, useState } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Box, Menu,  IconButton, Drawer, List, ListItem,  useMediaQuery,
    Grid,
    Divider
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import Logo from '../../assets/Logo.png';
import { CONST_ROUTE_PATH } from '../../routes/Routes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon


const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [aboutAnchorEl, setAboutAnchorEl] = useState(null);
    const [ResourceAnchorEl, setResourceAnchorEl] = useState(null);

    const { apiToken, logout } = useAuth();

    const menuItems = [
        "Cybersecurity", "Web Development", "Mobile Development", "Data Science",
        "Cloud Computing", "Blockchain", "DevOps", "IT Compliance",
    ];

    useEffect(() => {
        setAnchorEl(null);
    }, []);

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleResourceMenuOpen = (event) => setResourceAnchorEl(event.currentTarget)
    const handleResourceMenuClose = () => setResourceAnchorEl(null);
    const handleMenuClose = () => setAnchorEl(null);
    const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
    const handleAboutMenuOpen = (event) => setAboutAnchorEl(event.currentTarget);
    const handleAboutMenuClose = () => setAboutAnchorEl(null);

    const drawer = (
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
            <List>
                <ListItem button onClick={() => navigate('/')}>Home</ListItem>
                <ListItem button onClick={() => navigate(CONST_ROUTE_PATH.ABOUT)}>About</ListItem>
                <ListItem button onClick={() => navigate(CONST_ROUTE_PATH.CONTACT)}>Contact</ListItem>
                {/* <ListItem button onClick={() => navigate('/')}>Courses</ListItem> */}
                {apiToken ? (
                    <ListItem button onClick={logout}>Log Out</ListItem>
                ) : (
                    <ListItem button component={Link} to="/sign-in">Login</ListItem>
                )}
            </List>
        </Box>
    );

    const navItems = [
        { label: "Company", path: CONST_ROUTE_PATH.ABOUT },
        { label: "Partners", path: CONST_ROUTE_PATH.PARTNER },
        { label: "Careers", path: '/' },
        { label: "Community", path: '/' },
        { label: "Testimonials", path: '/' },
        { label: "FAQs", path: CONST_ROUTE_PATH.FAQ },
    ];
   
    

    const TrainingCard = ({ title, description }) => (
        <Grid
            item
            xs={6}
            sx={{
                color: "#0F2A47",
                transition: "all 0.2s ease",
                borderLeft: "2px solid #FF6F2F",
                mt: 2,
                '&:hover': {
                    color: "#FF6F2F",
                    borderLeft: "3px solid #ff5722",
                    cursor: 'pointer',
                    backgroundColor: "#FFF3E0",
                },
            }}
        >
            <Typography variant="h6" sx={{ fontWeight: 550, fontSize: "1.1rem", mb: 1, color: "#FF6F2F", mt: 2, }}>
                {title}
            </Typography>
            <Typography fontWeight={500} variant="body1" sx={{ mt: 1, mb: 2, color: "gray" }}>
                {description}
            </Typography>
        </Grid>
    );


    return (
        <AppBar sx={{ backgroundColor: "#FFF5EE", color: "black", position: "sticky", width: "100%" }}>
            <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0 }}>

                    <img
                        height="80px"
                        src={Logo}
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                    />
                    <Box sx={{ ml: "-2rem", textAlign: 'center', lineHeight: 1.2 }}>
                        <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>INSTITUTE OF</Typography>
                        <hr />
                        <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem" }}>CYBER SECURITY</Typography>
                    </Box>
                </Box>

                {isMobile ? (
                    <>
                        <IconButton color="inherit" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, }}>
                        <Button color="inherit" sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }} onClick={() => navigate('/')}>Home</Button>
                        <Button
                            color="inherit"
                            endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
                            sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }}
                            onClick={handleMenuOpen}
                        >
                            Courses
                        </Button>
                        <Fragment>
                            {/* Backdrop for dark background with blur effect */}
                            {Boolean(anchorEl) && (
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        top: 80,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 4,
                                        bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
                                        // backdropFilter: 'blur(2px)',   // Blur effect
                                    }}
                                    onClick={handleMenuClose}  // Closes the menu when the backdrop is clicked
                                />
                            )}

                            {/* Menu Content */}
                            <Menu
                                PaperProps={{
                                    sx: { mt: "1.3rem", bgcolor: '#FFF5EE', height: "auto", width: { lg: "80%" }, zIndex: 5, color: "black", boxShadow: 0 },
                                }}

                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <Box sx={{ padding: '2rem 4rem' }}>


                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>
                                            <Typography variant="h6" sx={{ fontWeight: 550, mb: 2, color: "#FF6F2F" }}>
                                                <ArrowForwardIosIcon sx={{ fontSize: '1rem', marginRight: '0.5rem' }} />
                                                All cybersecurity training
                                            </Typography>
                                            <Divider sx={{ borderWidth: 1 }} />
                                            <Grid container spacing={2} padding={2}>
                                                <TrainingCard
                                                    title="Bootcamp Courses"
                                                    description="Intensive, hands-on training in cybersecurity to help professionals tackle real-world security challenges."
                                                />
                                                <TrainingCard
                                                    title="Deep Dive Training"
                                                    description="Advanced cybersecurity courses that focus on specialized topics for experts looking to deepen their skills."
                                                />
                                                <TrainingCard
                                                    title="Learning Paths"
                                                    description="Structured courses designed to guide you step-by-step through your cybersecurity learning journey."
                                                />
                                                <TrainingCard
                                                    title="Intensive Training Programs"
                                                    description="Fast-paced, hands-on programs for professionals who want to quickly gain deep cybersecurity skills."
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" sx={{ fontWeight: 550, mb: 2, color: "#FF6F2F" }}>
                                                <ArrowForwardIosIcon sx={{ fontSize: '1rem', marginRight: '1rem' }} />
                                                Our Courses
                                            </Typography>
                                            <Divider sx={{ borderWidth: 1 }} />
                                            <Grid item xs={12}>
                                                <Grid padding={2} container spacing={5}>
                                                    {/* Left Grid Column */}
                                                    <Grid item xs={6}>
                                                        {menuItems.slice(0, menuItems.length / 2).map((item, index) => (
                                                            <Typography sx={{
                                                                color: "#0F2A47", transition: "all 0.3s ease", // Smooth transition for hover effects
                                                                '&:hover': {
                                                                    color: "#FF6F2F", // Change text color on hover (you can customize it)
                                                                    borderLeft: "2px solid #ff5722", // Change border color on hover
                                                                    cursor: 'pointer', // Show pointer cursor on hover
                                                                    backgroundColor: "#FFF3E0", // Add hover background color

                                                                }
                                                            }} mt={1} padding={"0.5rem 1.5rem"} borderLeft="2px solid #FF6F2F" fontWeight={520} variant='body1' key={index}>{item}</Typography>
                                                        ))}
                                                    </Grid>

                                                    {/* Right Grid Column */}
                                                    <Grid item xs={6}>
                                                        {menuItems.slice(menuItems.length / 2).map((item, index) => (
                                                            <Typography sx={{
                                                                color: "#0F2A47", transition: "all 0.3s ease", // Smooth transition for hover effects
                                                                '&:hover': {
                                                                    color: "#FF6F2F", // Change text color on hover (you can customize it)
                                                                    borderLeft: "2px solid #ff5722", // Change border color on hover
                                                                    cursor: 'pointer', // Show pointer cursor on hover
                                                                    backgroundColor: "#FFF3E0", // Add hover background color

                                                                }
                                                            }} mt={1} padding={"0.5rem 1.5rem"} borderLeft="2px solid #FF6F2F" fontWeight={520} variant='body1' key={index}>{item}</Typography>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid mt={2} sx={{padding:"1rem", '&:hover': {
                                                                    color: "#FF6F2F", // Change text color on hover (you can customize it)
                                                                    cursor: 'pointer', // Show pointer cursor on hover
                                                                    backgroundColor: "#FFF3E0", // Add hover background color

                                                                }}} item xs={12}>
                                                <Typography variant="h6" sx={{ fontWeight: 550, mb: 2, color: "#FF6F2F" }}>
                                                    <ArrowForwardIosIcon sx={{ fontSize: '1rem', marginRight: '1rem' }} />
                                                    Corporate Training                                                </Typography>
                                                <Divider sx={{ borderWidth: 1 }} />
                                                <Typography variant="body1" fontWeight={500} sx={{ mt: 1, mb: 2, color: "gray", }}>
                                                    Enhance your organization's cybersecurity expertise with our tailored, industry-recognized corporate training programs. Benefit from customizable solutions that meet your team's specific needs, while taking advantage of exclusive discounts for corporate training.
                                                </Typography>

                                            </Grid>



                                        </Grid>


                                    </Grid>

                                   
                                </Box>
                            </Menu>
                        </Fragment>
                        <Button endIcon={ResourceAnchorEl ? <ArrowDropUp /> : <ArrowDropDown />} color="inherit" sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }} onClick={handleResourceMenuOpen}
                        >Free Resources</Button>
                        <Fragment>
                            {/* Backdrop for dark background with blur effect */}
                            {Boolean(ResourceAnchorEl) && (
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        top: 80,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 4,
                                        bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
                                        // backdropFilter: 'blur(2px)',   // Blur effect
                                    }}
                                    onClick={handleResourceMenuClose}  // Closes the menu when the backdrop is clicked
                                />
                            )}

                            {/* Menu Content */}
                            <Menu
                                PaperProps={{
                                    sx: { mt: "1.3rem", bgcolor: '#FFF5EE', height: "auto", width: { lg: "60%" }, zIndex: 5, color: "black", boxShadow: 0 },
                                }}

                                anchorEl={ResourceAnchorEl}
                                open={Boolean(ResourceAnchorEl)}
                                onClose={handleResourceMenuClose}
                            >
                                <Box sx={{ padding: '2rem 4rem' }}>


                                    <Grid container spacing={5}>

                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ fontWeight: 550, fontSize: "1.5rem", mb: 2, color: "#FF6F2F" }}>
                                                Popular Resources
                                            </Typography>
                                            <Divider sx={{ borderWidth: 1 }} />
                                            <Grid container spacing={1} sx={{ pt: 2 }}>
                                                {[
                                                    { name: "Free Courses", route: "/" },
                                                    { name: "Blogs", route: "/" },
                                                    { name: "Documents", route: "/our-resources" },
                                                    { name: "Phishing", route: "/" },
                                                    { name: "Security Awareness", route: "/" },
                                                    { name: "Case Studies", route: "/" },

                                                ].map((resource, index) => (
                                                    <Grid item xs={6} md={4} key={index}>
                                                        <List to={resource.route} style={{ textDecoration: 'none', }}>
                                                            <ListItem
                                                                variant="h6"
                                                                sx={{
                                                                    borderLeft: "2px solid #FF6F2F", padding: "1rem 2rem", fontWeight: 550, fontSize: "1.2rem", mb: 0.5, color: "#0F2A47", transition: "all 0.3s ease", // Smooth transition for hover effects
                                                                    '&:hover': {
                                                                        color: "#FF6F2F", // Change text color on hover (you can customize it)
                                                                        borderLeft: "2px solid #ff5722", // Change border color on hover
                                                                        cursor: 'pointer', // Show pointer cursor on hover
                                                                        backgroundColor: "#FFF3E0", // Add hover background color

                                                                    }
                                                                }}
                                                            >
                                                                {resource.name}
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Box>
                            </Menu>
                        </Fragment>

                        

                        <Button
                            color="inherit"
                            endIcon={aboutAnchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
                            sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }}
                            onClick={handleAboutMenuOpen}
                        >
                            About
                        </Button>
                        <Fragment>
                            {/* Backdrop for dark background with blur effect */}
                            {Boolean(aboutAnchorEl) && (
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        top: 80,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 4,
                                        bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
                                        // backdropFilter: 'blur(2px)',   // Blur effect
                                    }}
                                    onClick={handleAboutMenuClose}  // Closes the menu when the backdrop is clicked
                                />
                            )}

                            {/* Menu Content */}
                            <Menu
                                PaperProps={{
                                    sx: { mt: "1.3rem", bgcolor: '#FFF5EE', height: "auto", width: { md: "60%" }, zIndex: 5, color: "black", boxShadow: 0 },
                                }}

                                anchorEl={aboutAnchorEl}
                                open={Boolean(aboutAnchorEl)}
                                onClose={handleAboutMenuClose}
                            >
                                <Box sx={{ padding: '1rem 4rem' }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2,mt:1, color: "#FF6F2F" }}>
                                        We are the leader in cybersecurity training
                                    </Typography>
                                    <Divider sx={{ borderWidth: 1 }} />
                                    <Grid container spacing={2} padding={2}>
                                        {navItems.map((item, index) => (
                                            <Grid item xs={4} key={index}>
                                                <ListItem
                                                    button
                                                    onClick={() => navigate(item.path)}
                                                    sx={{
                                                        fontWeight: 550,
                                                        p: 2,
                                                        borderLeft: "2px solid #FF6F2F",
                                                        cursor: "pointer",
                                                        color: "#0F2A47",
                                                        fontSize: "1.3rem",
                                                        '&:hover': {
                                                                        color: "#FF6F2F", // Change text color on hover (you can customize it)
                                                                        borderLeft: "2px solid #ff5722", // Change border color on hover
                                                                        cursor: 'pointer', // Show pointer cursor on hover
                                                                        backgroundColor: "#FFF3E0", // Add hover background color

                                                                    }
                                                    }}
                                                >
                                                    {item.label}
                                                </ListItem>
                                            </Grid>
                                        ))}
                                        <Grid item lg={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => navigate(CONST_ROUTE_PATH.CONTACT)}
                                                sx={{ borderColor: 'black', color: 'black' }}
                                            >
                                                Contact Us
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Menu>
                        </Fragment>


                        {apiToken ? (
                            <Button color="inherit" sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }} onClick={logout}>Log Out</Button>
                        ) : (
                            <Button component={Link} sx={{ color: "#0F2A47", textTransform: "none", fontWeight: 550, fontSize: "1rem" }} to="/sign-in" color="inherit">Login</Button>
                        )}
                    </Box>
                )}

            </Toolbar>
        </AppBar>
    );
};

export default Header;
