import React from 'react';
import { Box} from "@mui/material";
import DirectoryCard from '../DirectoryCard';
import Navbar from '../../Navbar';
import { Footer } from '../../Footer';


const CourseOverview = ({ onCourseSelect }) => {
  return (
    <>
      <Navbar />
      <Box sx={{padding:"2vw",bgcolor:"#FFF5EE"}}>
        <DirectoryCard onCourseSelect={onCourseSelect} />  {/* Pass onCourseSelect */}
      </Box>
      <Footer/>
    </>
  );
};

export default CourseOverview;
