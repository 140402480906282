import React, { useState } from 'react';
import {
    Button, Card, CardContent, TextField, Typography, Box, keyframes, Divider, IconButton,
    Grid
} from '@mui/material';
import { Google,  LinkedIn } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import LoginApiService from '../../services/LoginApiService';
import { useAuth } from '../../context/AuthContext';
import Logo2 from '../../assets/Logo2.png'
import { CONST_ROUTE_PATH } from '../../routes/Routes';
import HomeIcon from '@mui/icons-material/Home';


const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password should be at least 6 characters').required('Password is required'),
});

const menuItems = [
    "Cybersecurity", "Web Development", "Mobile Development", "Data Science",
    "Cloud Computing", "Blockchain", "DevOps", "IT Compliance","Artificial Intelligence"
];

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(70px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

function Login() {
    const navigate = useNavigate();
    const { loginWithApi } = useAuth();
    const [error, setError] = useState(null);
    const handleLogin = async (values) => {
        try {
            const response = await LoginApiService.manual(values);
            if (response && response.token) {
                const { token, userId } = response;
                loginWithApi(token, userId);
                localStorage.setItem('email', values.email);
                navigate(CONST_ROUTE_PATH.USER_DASHBOARD);
            }
        } catch (error) {
            setError(error.response?.data?.message?.toString());
        }
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', }}>
            {/* Left Section */}
            <Box
                sx={{
                    width: "100%",
                    backgroundImage: "url(https://niveussolutions.com/wp-content/uploads/2023/01/automated-data-translation.jpg)",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                    // background: 'linear-gradient(135deg, #004aad, #673ab7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                    color: 'white',
                    textAlign: 'center',
                    padding: '2rem',
                }}
            >
                <Box sx={{ width: { xs: '100%', md: '60%', lg: '70%' }, }}>
                    <Button startIcon={<HomeIcon />} onClick={() => navigate(CONST_ROUTE_PATH.HOME)} variant='text' color='success' sx={{ position: "absolute", top: 30, left: 30, color: "white", textTransform: "none" }}>Home</Button>
                    <img src={Logo2} style={{
                        width: "200px",

                    }} alt="Logo..." />
                    <Typography variant="h3" sx={{color:"#FF6F2F", fontWeight: 'bold', animation: `${slideIn} 1s ease-out`, fontSize: { xs: "2rem", md: "3rem" } }}>
                        Welcome Back to ICS !
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, mt: 2, animation: `${slideIn} 1s ease-out` }}>
                        Unlock your learning journey and continue exploring our comprehensive IT courses. Whether you're mastering Web Development, Cybersecurity, or any other in-demand skills, your next step is just a sign-in away.


                    </Typography>
                    <Typography variant="h5" sx={{color:"#FF6F2F", fontWeight: 'bold', mt: 4, animation: `${slideIn} 1s ease-out`, }}>
                    For support, contact us at:                    </Typography>
                    <Typography variant="body1" sx={{fontWeight:600,mt:2, animation: `${slideIn} 1s ease-out`,}}>
                        Email : ics.delhi41@gmail.com <br />
                        Phone : +91-8178210903
                    </Typography>
                    <Typography variant="h5" sx={{color:"#FF6F2F", fontWeight: 'bold', mt: 3, animation: `${slideIn} 1s ease-out`, }}>
                        Explore Our Courses:
                    </Typography>
                    <Grid  container spacing={2} sx={{ mt: 2,ml:{md:5}, animation: `${slideIn} 1s ease-out`,  }}>
                        {menuItems.map((course, index) => (
                            <Grid item xs={6} md={4}  justifyContent="center" alignItems="center"   key={index}>
                                <Typography textAlign="center" variant="body1" sx={{ fontWeight: 'bold' }}>{course}</Typography>
                            </Grid>
                        ))}
                    </Grid>

                    
                </Box>

                {/* Right Section */}
                <Box
                    sx={{
                        width: { xs: '100%', md: '40%', lg: '30%' },
                        mt: { xs: "1rem", md: "0rem" },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: { xs: '2rem', md: '0' },
                        animation: `${slideIn} 1s ease-out`,
                        boxShadow: 3,

                    }}
                >
                    <Card
                        sx={{
                            width: '100%',
                            padding: '2rem',

                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '2rem' }}>
                                Welcome Back!
                            </Typography>

                            <Formik
                                initialValues={{ email: '', password: '' }}
                                validationSchema={LoginSchema}
                                onSubmit={handleLogin}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <Box mb={2}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                error={touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                name="password"
                                                type="password"
                                                label="Password"
                                                variant="outlined"
                                                error={touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                            />
                                        </Box>

                                        {error && <Typography color="error" variant="body2">{error}</Typography>}

                                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, padding: '0.75rem' }}>
                                            Login Now
                                        </Button>

                                        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '2rem' }}>
                                            Or log in with:
                                        </Typography>

                                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                                            <IconButton color="primary"><Google /></IconButton>
                                            {/* <IconButton color="default"><GitHub /></IconButton> */}
                                            <IconButton color="primary"><LinkedIn /></IconButton>
                                        </Box>

                                        <Divider sx={{ my: 2 }} />

                                        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '2rem' }}>
                                            Don't have an account? <Link to="/sign-up">Create a new account</Link>
                                        </Typography>

                                        {/* <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '1rem' }}>
                                        <Link to="/forgot-password">Forgot password? Click here</Link>
                                    </Typography> */}
                                    </Form>
                                )}
                            </Formik>
                        </CardContent>
                    </Card>
                </Box>
            </Box>


        </Box>
    );
}

export default Login;

