import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignUp from '../pages/Auth/Signup';
import { CONST_ROUTE_PATH } from '../routes/Routes';
import Login from '../pages/Auth/Login';

export const AuthLayout = () => {
  return (
    <Routes>
      {/* Define the sign-up route */}
      <Route path={CONST_ROUTE_PATH.SIGN_IN} element={<Login />} />
      <Route path={CONST_ROUTE_PATH.SIGN_UP} element={<SignUp />} />

    </Routes>
  );
};
