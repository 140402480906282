import React, { useState } from 'react';
import {
    Box, Typography, Button, Grid, Avatar,  IconButton, 
    Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import Navbar from '../components/common/Navbar';
import { Footer } from '../components/common/Footer';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';

const UserProfile = () => {
    const { userdetails } = useAuth(); // Fetch user details from context

    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleEditProfile = () => {
        // Logic for editing profile
    };

    const handleChangePasswordClick = () => {
        setOpenPasswordDialog(true); // Open the dialog when "Change Password" button is clicked
    };

    const handlePasswordDialogClose = () => {
        setOpenPasswordDialog(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
    };

    const handlePasswordSubmit = async () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        // Call the API to check the old password and change it
        try {
            const response = await fetch('/api/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldPassword,
                    newPassword,
                }),
            });

            const result = await response.json();

            if (response.ok) {
                alert('Password changed successfully!');
                handlePasswordDialogClose();
            } else {
                setErrorMessage(result.message || 'Failed to change password');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Navbar />
            <Box sx={{ minHeight: '60vh', backgroundColor: '#FFF5EE', width: "100%" }}>
                {/* Main Container */}
                <Grid container spacing={4}>
                    {/* User Profile Details */}
                    <Grid item xs={12} >
                        <Box sx={{ padding: 3, }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {/* Avatar and Profile */}
                                <Avatar sx={{ width: 120, height: 120, fontWeight: 600, fontSize: "4rem", backgroundColor: '#FF6F2F' }}>
                                    {userdetails?.firstname.charAt(0).toUpperCase()}

                                </Avatar>
                                <Typography ml={5} variant="h5" sx={{ color: "#FF6F2F", fontWeight: 'bold', marginTop: 2 }}>
                                    {`${userdetails?.firstname} ${userdetails?.lastname}`}
                                    <IconButton onClick={handleEditProfile} sx={{ marginLeft: 1, color: '#FF6F2F' }}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Typography>
                                <Typography mt={1} fontWeight={550} variant="h6" color="textSecondary">{userdetails?.email}

                                </Typography>
                                {/* <Typography mt={0.5} variant="body1" color="textSecondary"><strong>User ID :</strong> {userdetails?._id}</Typography> */}
                                {/* <Typography variant="body2" color={userdetails?.isVerified ? 'green' : 'red'} sx={{ marginTop: 1, fontWeight: 'bold' }}> */}
                                {/* {userdetails?.isVerified ? 'Verified Account' : 'Account not verified'}
                </Typography> */}
                                <Typography variant="body1" mt={0.5} color="textSecondary" sx={{ marginBottom: 2 }}><strong>
                                    Status: </strong>  {userdetails?.status}</Typography>

                                {/* Change Password */}
                                <Button
                                    variant="outlined"
                                    startIcon={<LockIcon />}
                                    onClick={handleChangePasswordClick}
                                    sx={{ color: '#FF6F2F', borderColor: '#FF6F2F', marginBottom: 2 }}
                                >
                                    Change Password
                                </Button>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Footer />

            {/* Change Password Dialog */}
            <Dialog open={openPasswordDialog} onClose={handlePasswordDialogClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Old Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        margin="dense"
                        label="New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        margin="dense"
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    {errorMessage && (
                        <Typography variant="body2" color="error">{errorMessage}</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePasswordSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserProfile;
