import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';

const CallToAction = () => {
    const navigate = useNavigate();
    const { apiToken } = useAuth();

    const handleclick =()=>{
        if(apiToken){
            navigate(CONST_ROUTE_PATH.USER_DASHBOARD)
        }else{
            navigate(CONST_ROUTE_PATH.SIGN_IN)
        }
    }
    return (
        <Box  sx={{backgroundColor:"#FDF5E6" , height:"33vh"}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#0F2A47', // Light background color
                    padding: '40px 20px',
                    // margin: '40px 0',
                    color:"white"
                }}
            >
                <Typography fontStyle="italic" variant="h4" component="h2" gutterBottom>
                    Ready to Start Learning?
                </Typography>
                <Typography fontFamily="sans-serif" variant="h6" component="p" gutterBottom>
                    Join thousands of learners already advancing their skills.
                </Typography>
                <Button
                onClick={handleclick}
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{
                        fontStyle:"italic",
                        fontWeight:600,
                        marginTop: 2,
                        padding: '10px 20px',
                        borderRadius: 2,
                        backgroundColor: "#FF6F2F"
                    }}
                >
                    Get Started for Free
                </Button>
            </Box>
        </Box>

    );
};

export default CallToAction;
