import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import Plainbackgroud from '../../../assets/Plainbackgroud.png';
import Header from '../Header';
import { Footer } from '../Footer';

const PartnerSection = () => {
    return (
        <>
            <Header />
            <Box
                sx={{
                    // backgroundImage: `url(${partnerBackground})`,
                    // backgroundSize: 'cover',
                    // backgroundPosition: 'center',
                    backgroundColor: "#FFF5EE",
                    padding: '40px 0',
                    color: '#fff',
                }}
            >
                <Container maxWidth="lg">
                    {/* Banner Section */}
                    <Box
                        sx={{
                            backgroundImage: `url(${Plainbackgroud})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            padding: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', color: '#fff' }}>
                            INFOCUS-IT – Our Trusted Partner
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ marginTop: '10px', color: '#fff' }}>
                            Strengthening Cybersecurity Through Training with ICS
                        </Typography>
                    </Box>

                    {/* Logo Section */}
                    <Box display="flex" justifyContent="center" mb={5}>
                        <img src="https://infocus-it.com/wp-content/uploads/2024/04/info.bmp" alt="INFOCUS-IT Logo" style={{ width: '200px' }} />
                    </Box>

                    {/* Partner Content Section */}
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: "#FF6F2F" }}>
                        About INFOCUS-IT
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ marginBottom: '30px', color: "gray" }}>
                        INFOCUS-IT is a leading provider of cybersecurity solutions dedicated to protecting businesses
                        from modern cyber threats. Through a strong partnership with ICS, they deliver world-class
                        cybersecurity services and offer tailored solutions to safeguard digital assets.
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ marginBottom: '30px', color: "gray" }}>
                        With a deep understanding of today’s evolving cyber landscape, INFOCUS-IT excels in providing
                        comprehensive services, including network security assessments, web application security, and
                        mobile application security. Their focus on innovation and proactive defense strategies allows
                        businesses to stay ahead of emerging threats and vulnerabilities.
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ marginBottom: '30px', color: "gray" }}>
                        INFOCUS-IT also assists organizations in achieving critical regulatory compliance with services
                        such as ISO 27001 implementation and DPDP Act compliance, ensuring that security measures are
                        not only robust but also in line with international standards.
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ marginBottom: '30px', color: "gray" }}>
                        The partnership between ICS and INFOCUS-IT ensures that both companies work together to deliver
                        exceptional cybersecurity training programs, developing skilled professionals capable of protecting
                        the digital infrastructure of any organization. Our joint efforts focus on building a strong, secure
                        future for businesses worldwide.
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ marginBottom: '30px', color: "gray" }}>
                        Whether you’re looking to secure your web or mobile applications, conduct thorough security audits,
                        or receive hands-on training for your teams, INFOCUS-IT, in collaboration with ICS, provides the expertise
                        and resources necessary to protect your business in an increasingly hostile cyber environment.
                    </Typography>

                    {/* Service Cards Section */}
                    <Grid container spacing={7}>
                        {services.map((service, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        height: '100%',
                                        width:{md:"100%"},
                                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#f4f4f4' }}>
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#e0e0e0', marginTop: '10px' }}>
                                        {service.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Call to Action Section */}
                    <Box textAlign="center" mt={10}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: "#FF6F2F" }}>
                            Partner with ICS & INFOCUS-IT
                        </Typography>
                        <Typography variant="body1" sx={{ margin: '20px 0', color: 'gray' }}>
                            Together, we provide advanced training and world-class cybersecurity solutions to protect
                            your organization from evolving cyber threats.
                        </Typography>
                        <Button variant="contained" href="https://infocus-it.com/about-us/" target="_blank" rel="noopener noreferrer" size="large" sx={{ backgroundColor: '#ff6f61', color: '#fff' }}>
                            Learn More About Our Partnership
                        </Button>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>

    );
};

// Example services to be listed
const services = [
    {
        title: 'Web Application Security',
        description:
            'Secure your web applications with a focus on vulnerability testing and fortification to prevent unauthorized access.',
    },
    {
        title: 'Network Security Assessment',
        description:
            'A comprehensive review of your network infrastructure to identify risks and fortify your defense strategy.',
    },
    {
        title: 'ISO 27001 Implementation',
        description:
            'Meet international security standards and safeguard your information assets with expert ISO 27001 implementation.',
    },
    {
        title: 'Mobile Application Security',
        description:
            'Protect your mobile applications against vulnerabilities that can expose sensitive user data.',
    },
    {
        title: 'Security Architecture Review',
        description:
            'Review and optimize your security architecture to align with industry standards and reduce cyber risks.',
    },
    {
        title: 'Source Code Review',
        description:
            'Perform detailed code reviews to identify and fix security vulnerabilities at the code level.',
    },
    {
        title: 'DPDP Act Compliance',
        description:
            'Ensure your organization complies with the Data Protection and Privacy Act, protecting user privacy and mitigating risks.',
    },
    {
        title: 'Security Awareness Simulations',
        description:
            'Interactive simulations to train employees and raise awareness of potential cyber threats.',
    },
    {
        title: 'Virtual CISO Services / DPO',
        description:
            'Provide expert-level strategic guidance with Virtual CISO services or ensure regulatory compliance with DPO services.',
    },
];

export default PartnerSection;
