import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

const CustomBar = () => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', color:"black", justifyContent: "center", bgcolor: '#FFE4B5', p: 2 }}>
           
            <Typography variant="h6" sx={{display:{xs:"none",md:"flex"}}} >
                Rated the Best
            </Typography>

            <Box sx={{ display: 'flex', ml:{md:2 }, alignItems: "center", justifyContent: "center", gap:{xs:1,sm:2} }}>
                <Avatar 
                    sx={{ width: {xs:20 ,sm:32}, height:  {xs:20 ,sm:32} }} 
                    src='https://www.infosectrain.com/wp-content/themes/Divi/assets/images/home/stick/google.png' 
                    alt='Google' 
                />
                <Typography variant="body1" fontWeight={550} >4.8/5</Typography>
                
                <Avatar 
                    sx={{ width: {xs:20 ,sm:32} ,height: {xs:20 ,sm:32} }} 
                    src='https://www.infosectrain.com/wp-content/themes/Divi/assets/images/home/stick/trustpilot.png' 
                    alt='Trustpilot' 
                />
                <Typography variant="body1" fontWeight={550} >4.9/5</Typography>

                <Avatar 
                    sx={{ width: {xs:20 ,sm:32}, height:  {xs:20 ,sm:32} }} 
                    src='https://www.infosectrain.com/wp-content/themes/Divi/assets/images/home/stick/glassdor.png' 
                    alt='Glassdoor' 
                />
                <Typography variant="body1" fontWeight={550} >4.8/5</Typography>
            </Box>
            
            <Box sx={{ display: 'flex',  ml:{xs:1, md:2} , textAlign: 'center',gap:{xs:1,sm:2} }}>
                <Typography variant="body1" sx={{fontSize:{xs:"13px",sm:"17px"}}}  fontWeight={550} >1,000+ Learners</Typography>
                <Typography variant="body1" sx={{fontSize:{xs:"13px",sm:"17px"}}} fontWeight={550} >25+ Courses</Typography>
            </Box>
        </Box>
    );
};

export default CustomBar;
