import { createContext, useContext, useState, useEffect } from 'react';

// Create a context for course-related data
const CoursesContext = createContext();

// UserContextProvider component to wrap the parts of the app where you want to provide access to the context
export const CoursesContextProvider = ({ children }) => {
  // Initialize state with data from localStorage (if available) or an empty array
  const [selectedcourse, setSelectedCourse] = useState(() => {
    const storedCourses = localStorage.getItem('selectedcourse');
    return storedCourses ? JSON.parse(storedCourses) : [];
  });

  // Effect to update localStorage whenever selectedcourse changes
  useEffect(() => {
    if (selectedcourse.length > 0) {
      localStorage.setItem('selectedcourse', JSON.stringify(selectedcourse));
    }
  }, [selectedcourse]);

  // Function to update selected courses and save to localStorage
  const updateSelectedCourse = (newCourse) => {
    setSelectedCourse(newCourse);
  };

  return (
    <CoursesContext.Provider value={{ selectedcourse, setSelectedCourse: updateSelectedCourse }}>
      {children}
    </CoursesContext.Provider>
  );
};

// Custom hook to use the CoursesContext in components
export const useCoursesContext = () => useContext(CoursesContext);
