import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";


const UserApiService = {
  // Fetch user details by email
  userDetails: async (userId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_USER_DETAILS}?userId=${userId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Caught error while fetching user details', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  //SerachUser 
  SearchUser: async (location,gender,ageRange,  religion , language,UserId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_SEARCH_USER}?location=${location}&gender=${gender}&ageRange=${JSON.stringify(ageRange)}&religion=${religion}&language=${language}&userId=${UserId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Caught error while fetching ', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },


  // Update user details
  updateUser: async (userData, apiToken) => {
    try {
    //   const headers = {
    //     'Authorization': `Bearer ${apiToken}`, // Assuming you use Bearer token for auth
    //     'Content-Type': 'application/json',
    //   };
      return await APIService.put(APIConstants.ENDPOINT_UPDATE_USER,userData);
    } catch (err) {
      console.error('UserApiService: Caught error while updating user details', err, userData);
      throw err; // Optionally re-throw to handle in calling code
    }
  }
};

export default UserApiService;
